/* body{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
.column {
  margin: 0;
  padding: 0;
} */
.wraper {
  width: 100%;
  height: auto;
  font-family: "SF UI Display", sans-serif;
}
/*----------------------- Left Side ----------------------*/

.left-container {
  background: #2d0050 url(../images/onbording-background.png) no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 100px;
}
.left-content {
  width: 100%;
  padding: 0 calc(100 * (70vw / 1366));
}
.left-container .left-content .onbheader {
  font-size: calc(100 * (60vw / 1920));
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 15px;
}
.left-container .left-content .onbheader > span {
  font-weight: 700;
  color: #fff;
}
.left-container .left-content .sub-header {
  font-size: calc(100 * (30vw / 1920));
  line-height: 33px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 30px;
}

/*----------------------------------------- Right Side ------------------------------------------*/

/*------------------ Right Side Intro Component------------------*/

.right-container {
  min-height: 100vh;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.right-container .right-header .logo > img {
  margin-left: 0;
}
.right-wrapper {
  width: 100%;
  padding-bottom: 30px;
  height: 100%;
  display: flex;
  align-items: center;
}
.right-disclosure-text {
  color: #22134b;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
}
.agreement-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  margin-top: 45px;
}
.agreement-group .agreement {
  display: flex;
  gap: 15px;
  align-items: baseline;
}
.agreement-group .agreement p {
  color: #22134b;
  font-size: calc(100 * (17vw / 1920));
  font-style: normal;
  font-weight: 500;
}
.agreement-group .error {
  color: #ec00b1;
}
.agreement-group .agreement p span {
  color: #5a00ca;
  text-decoration: underline;
  cursor: pointer;
}
.agreement-group .custom-checkbox {
  border: 2px solid #5a00ca !important;
  background-color: #d9d9d9 !important;
}
.agreement-group .custom-checkbox:checked {
  background-color: #5a00ca !important;
}
.right-header {
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: calc(100 * (50vw / 1366));
  padding-left: calc(100 * (50vw / 1366));
  margin-bottom: 30px;
  margin-top: 30px;
}
.right-header-text {
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */
  padding-right: calc(100 * (50vw / 1366));
  padding-left: calc(100 * (50vw / 1366));
  margin-bottom: 30px;
  margin-top: 10px;
}
.right-header-text .right-header-text-p {
  margin-bottom: 5px;
  font-size: 31px;
  font-weight: 600;
  text-transform: uppercase;
}
.head-title {
  /* padding-right: calc(100 * (318vw / 1366)); */
  margin-bottom: 28px;
  margin-top: 10px;
}
.head-title h3 {
  color: #22134b;
  font-size: 35px;
  font-weight: 700;
}
.disclosure-title {
  margin-bottom: 35px;
  margin-top: 20px;
}
.disclosure-title h4 {
  color: #22134b;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}
.right-header .logo img {
  width: calc(100 * (277vw / 1920));
}
.right-header .icon img {
  width: calc(100 * (40vw / 1920));
}
.right-content {
  width: 100%;
  padding-right: calc(100 * (50vw / 1366));
  padding-left: calc(100 * (50vw / 1366));
  display: flex;
  flex-direction: column;
}
.right-content > h3 {
  font-size: calc(100 * (60vw / 1920));
  font-weight: 400;
  color: #8509b1;
  margin-bottom: 9px;
}
.right-content > h3 > span {
  font-weight: 700;
  color: #ec00b1;
}
.right-content > p {
  font-size: calc(100 * (24vw / 1920));
  line-height: 45px;
  font-weight: 400;
  color: #8509b1;
  margin-bottom: 30px;
}
.stepper {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 100px;
  padding-left: 22px;
  display: flex;
  flex-direction: column;
}
.step {
  display: flex;
  align-items: center;
}
.step-number {
  background-color: #7100ff;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 10px;
}
.step-content {
  font-size: calc(100 * (24vw / 1920));
  font-weight: 400;
  line-height: 135%;
  color: #22134b;
  margin-left: 20px;
}
.divider {
  border-left: 2px dashed #7100ff;
  height: 50px;
  margin: 0 10px 0 22px;
}
.right-content .btn {
  width: 300px;
  border-radius: 50px;
  background-color: #7100ff;
  color: #ffffff;
  font-size: calc(100 * (24vw / 1920));
  font-weight: 600;
  text-align: center;
  padding: 15px 0px;
  display: table;
  /* align-items: center; */
  margin-left: 22px;
}
.right-content .btn:hover {
  background-color: #7100ff;
  color: #ffffff;
}

/*------------------ Right Side Form Component------------------*/

.styled-form {
  max-width: 100%;
  padding: 0 calc(100 * (50vw / 1366));
  width: 100%;
}
.form-group {
  margin-bottom: 20px;
}
.form-group > label {
  display: block;
  font-weight: 500;
  font-size: calc(100 * (16vw / 1920));
  color: #22134b;
}
.form-group .error {
  color: #ec00b1;
}
.form-group .impt {
  color: #061737;
}
.form-group .input-grouped {
  display: flex;
  align-items: baseline;
  width: 100%;
}
.form-group .input-grouped > label {
  color: #22134b;
}
.form-group .color-box {
  width: 100%;
  height: auto;
  padding-top: 15px;
  padding-left: 55px;
}
.form-group .custom-check input {
  display: none;
}
.form-group .custom-check span {
  display: table;
  width: 43px;
  height: 43px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 2px solid #fff;
  margin-right: 11px;
}
.form-group .custom-check input:checked ~ span {
  border: 1px solid #fff;
}
.form-group .custom-check input:checked ~ span:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 5px;
  width: 27px;
  height: 14px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
}
.input-grouped .field-group {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.input-grouped .name-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.input-grouped .name-group .name-single {
  width: 46%;
  display: flex;
  flex-direction: column;
}
.input-grouped .company-single {
  width: 85%;
  display: flex;
  flex-direction: column;
}
.form-group .form-company-group {
  display: flex;
  /* justify-content: space-between; */
  gap: 40px;
}
.form-group .form-label {
  display: block;
  font-weight: 500;
  font-size: calc(100 * (19vw / 1920));
  color: #22134b;
  flex: none;
  margin-right: 8px;
}
/* span {
  color: #ec00b1;
} */
.form-group .radio-btn-group {
  display: flex;
  align-items: center;
  column-gap: 68px;
  margin-top: 18px;
  flex-wrap: wrap;
  row-gap: 16px;
}
.form-group .radio-btn-group .radio-btn {
  display: flex;
  align-items: center;
}
.form-group .radio-btn-group .radio-text {
  color: #22134b;
  font-size: calc(100 * (18vw / 1920));
  font-weight: 300;
  padding-left: 10px;
}
.form-group .radio-btn-group .radio-input {
  width: 16px;
  height: 16px;
}
.radio-input:checked {
  color: #ff5733; /* Change this to the desired color */
}
.form-address {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.form-address .form-address-content {
  width: 46%;
  display: flex;
  flex-direction: column;
}
.form-address .form-address-content > input {
  width: 100%;
  padding: 8px;
  border: none;
  border-bottom: 1px solid #a134f6;
  font-size: 20px;
  outline: none;
  margin-top: 10px;
}
.input-form {
  width: 100% !important;
  padding: 8px !important;
  border: none !important;
  border-bottom: 1px solid #22134b !important;
  font-size: calc(100 * (16vw / 1920)) !important;
  outline: none !important;
  border-radius: 0 !important;
}
.input-form .css-13cymwt-control {
  border: none;
}
.input-form .css-1u9des2-indicatorSeparator {
  display: none;
}
input::placeholder {
  font-weight: 400;
  color: #c8bfe5;
}
/* option:hover {
  background-color: #211c3e !important;
} */
/* select {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
} */
/* option {
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  color: blue;
  background-color: yellow;
}
select option:hover option:active option:focus{
  background-color: rgb(80, 80, 56) !important;
} */
.btn-form-container {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  gap: 15px;
}
.back-btn {
  background-color: #f0f0f0;
  border: none;
  border-radius: 50px;
  color: #2e2e2e;
  cursor: pointer;
  font-size: calc(100 * (24vw / 1920));
  font-weight: 600;
  text-align: center;
  padding: 10px 50px;
  /* margin-right: 20px; */
}
.back-btn:hover {
  background-color: #d6cdcd;
}
.form-btn {
  background-color: #22134b;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: calc(100 * (24vw / 1920));
  font-weight: 600;
  text-align: center;
  padding: 10px 50px;
}
.form-btn:hover {
  background-color: #351e75;
}

/*------------------ Right Side Thanks Component------------------*/

.thanks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding: 0 50px;
  width: 100%;
}
.thanks-container > h3 {
  font-size: calc(100 * (38vw / 1920));
  font-weight: 500;
  color: #8509b1;
  text-transform: uppercase;
  margin-bottom: 34px;
}
.thanks-container > p {
  font-size: calc(100 * (30vw / 1920));
  font-weight: 400;
  color: #211c3e;
  margin-bottom: 50x;
}
.thanks-container .thanks-p {
  font-size: calc(100 * (22vw / 1920));
  font-weight: 700;
  color: #8509b1;
  text-transform: uppercase;
  margin-top: 30px;
}
.slider {
  width: 100%;
  height: auto;
}
.slider .item {
  width: 100%;
  height: auto;
  position: relative;
  background: #fff494;
  padding: calc(100 * (30vw / 1920));
  border-radius: 30px;
  min-height: calc(100 * (435vw / 1920));
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-wrap: wrap;
}
.grow {
  flex-grow: 1;
}
.slider .item .top {
  width: 100%;
}
.slider .itemfirst {
  width: 100%;
  height: auto;
  position: relative;
  background: #ec00b1;
  padding: calc(100 * (30vw / 1920));
  border-radius: 30px;
  min-height: calc(100 * (435vw / 1920));
}
.slider .itemfirst h3 {
  font-size: calc(100 * (58vw / 1920));
  font-style: normal;
  font-weight: 400;
  color: #fff;
}
.slider .itemfirst h3 span {
  color: #fff;
  font-weight: 700;
}
.slider .itemfirst p {
  font-size: calc(100 * (40vw / 1920));
  font-style: normal;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
  text-align: right;
  width: 80%;
}
.slider .itemfirst p span {
  background: rgba(255, 255, 255, 0.8);
  color: #ec00b1;
  padding: 0 10px;
  border-radius: 8px;
  display: inline-block;
}
.slider .item.one {
  background: #fff494;
  background: rgba(34, 19, 75, 0.7);
}
.slider .item.three {
  background: #fff;
}
.slider .item.four {
  background: #c9ffdd;
}

.slider .item p {
  font-size: calc(100 * (29vw / 1920));
  font-style: normal;
  font-weight: 600;
  color: #22134b;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.slider .item.one p {
  color: #fff;
}
.slider .item h3 {
  font-size: calc(100 * (60vw / 1920));
  font-style: normal;
  font-weight: 400;
  color: #22134b;
}
.slider .item h3 span {
  font-weight: 700;
}
.slider .item.one h3 {
  color: #fff;
}
.slider .item h4 {
  font-size: calc(100 * (40vw / 1920));
  font-style: normal;
  font-weight: 400;
  color: #22134b;
  margin-bottom: 30px;
}
.slider .item.one h4 {
  color: #fff;
}
.slider .item h6 {
  font-size: calc(100 * (26vw / 1920));
  font-style: normal;
  font-weight: 400;
  color: #22134b;
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
}
.slider .item.one h6 {
  color: #fff;
}
.slider .item h6 span {
  display: inline-block;
  width: calc(100 * (20vw / 1920));
  height: calc(100 * (20vw / 1920));
  position: relative;
  top: 2px;
  border-radius: 50%;
  background: #8509b1;
  margin-right: 10px;
}
.slider .item.one h6 span {
  background: #30f077;
}
.slider .item.three h6 span {
  background: #656bff;
}
.slider .item.three h6 span {
  background: #ec00b1;
}
.slider .carousel-control-next {
  right: calc(100 * (-75vw / 1920)) !important;
}
.slider .carousel-control-prev {
  left: calc(100 * (-75vw / 1920)) !important;
}
.slider .carousel-indicators {
  bottom: -50px !important;
}
.carousel-indicators [data-bs-target] {
  width: 22px !important;
  height: 8px !important;
  border-radius: 10px !important;
  background-clip: unset;
  border-top: 0;
  border-bottom: 0;
}
.slider .carousel-indicators .active {
  background: #30f077;
  height: 8px !important;
}

.AI-modal-disclosure .modal-dialog {
  max-width: 600px;
}
.AI-modal-disclosure.odrdash .modal-dialog {
  max-width: 1200px;
}
.AI-modal-disclosure.formPopup .modal-dialog {
  max-width: 748px;
}
.AI-modal-disclosure .modal-content {
  border-radius: 30px;
  border: 5px solid #5a00ca;
}
.AI-modal-disclosure .modal-content .modal-body {
  padding: 60px 30px 20px 30px;
}
.AI-modal-disclosure .modal-content .modal-body .item {
  width: 100%;
  height: auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.AI-modal-disclosure .modal-content .modal-body .close-btn {
  background: #fff;
  position: absolute;
  right: 10px;
  top: 12px;
  border: none;
}
.AI-modal-disclosure .modal-content .modal-body .item .right-disclosure-text {
  color: #22134b;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}
.AI-modal-disclosure .modal-content .modal-body .disclosure-title h4 {
  color: #22134b;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.Launch-modal .modal-dialog {
  max-width: 600px;
}
.Launch-modal.odrdash .modal-dialog {
  max-width: 1200px;
}
.Launch-modal.formPopup .modal-dialog {
  max-width: 748px;
}
.Launch-modal .modal-content {
  border-radius: 30px;
  /* border: 5px solid #FF8900; */
  background-color: #ff8900;
  position: relative;
}
.Launch-modal .modal-content .modal-body .close-btn {
  background: #ff8900;
  position: absolute;
  right: 10px;
  top: 12px;
  border: none;
}
.Launch-modal .modal-content .modal-body {
  padding: 60px 0px 0px 0px;
  /* width: 100%; */
}
.Launch-modal .modal-content .modal-head {
  /* position: absolute; */
  color: #fff;
  text-align: center;
}
.Launch-modal .modal-content .modal-head > h1 {
  font-size: 35px;
  font-weight: 600;
}
.Launch-modal .modal-content .modal-head > p {
  font-size: 28px;
  margin-top: -13px;
}
.Launch-modal .modal-content .blue-part {
  /* position: absolute; */
  padding-top: 27px;
  width: 100%;
  height: 30%;
  /* left: 0;
  bottom: 0; */
  background: rgb(125, 85, 226);
  border-radius: 30px 30px 30px 30px;
}
.Launch-modal .modal-content .blue-part > h1 {
  font-size: 35px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.Launch-modal .modal-content .blue-part .stepper-modal {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  margin-bottom: 46px;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
  flex-direction: column;
}
.Launch-modal .modal-content .blue-part .step-modal {
  display: flex;
  justify-content: space-between;
}
.Launch-modal .modal-content .blue-part .step-modal .step-left {
  display: flex;
  /* align-items: center; */
}
.Launch-modal .modal-content .blue-part .step-number-modal {
  background-color: #22134b;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-right: 10px;
}
.Launch-modal .modal-content .blue-part .step-round-modal {
  background-color: #fff;
  color: #fff;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 30px;
}
.Launch-modal .modal-content .blue-part .step-content-modal {
  font-size: calc(100 * (24vw / 1920));
  font-weight: 400;
  line-height: 135%;
  color: #fff;
  margin-left: 20px;
}
.Launch-modal .modal-content .blue-part .divider {
  border-left: 0px dashed #ff8900;
  height: 27px;
  margin: 0 10px 0 22px;
}
.Launch-modal .modal-content .blue-part .trail-price-modal {
  display: flex;
  flex-direction: row;
  gap: 70px;
  justify-content: center;
}
.Launch-modal .modal-content .blue-part .trail-price-modal .price-box-modal {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}
.Launch-modal
  .modal-content
  .blue-part
  .trail-price-modal
  .price-box-modal
  > img {
  width: 20px;
}
.Launch-modal
  .modal-content
  .blue-part
  .trail-price-modal
  .price-box-modal
  > p {
  color: #fff;
  font-size: 15px;
  font-weight: 500;
}
.Launch-modal .modal-content .blue-part .modal-btn-container {
  margin-top: 40px;
  padding-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
}
.Launch-modal .modal-content .blue-part .modal-btn-container .modal-btn {
  width: 100%;
  background-color: #22134b;
  border: none;
  border-radius: 50px;
  color: #fff;
  cursor: pointer;
  font-size: calc(100 * (24vw / 1920));
  font-weight: 600;
  text-align: center;
  padding: 10px 50px;
}
.Launch-modal .modal-content .blue-part .modal-btn-container .modal-btn > img {
  color: #fff;
}
.Launch-modal .modal-content .blue-part .modal-btn-container .spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.Launch-modal .modal-content .blue-part .modal-btn-container .spinner {
  color: #ffffff;
  font-size: 28px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 35px;
  height: 35px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.Launch-modal .modal-content .blue-part .modal-btn-container .spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.Launch-modal .modal-content .blue-part .modal-btn-container .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #22134b;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.Launch-modal .modal-content .blue-part .modal-btn-container .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #22134b;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------CHECKOUT--------------------------------------------------*/
.checkout-container {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}
.checkout-container .payment-form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  background-color: #9b6afe;
  margin-bottom: 12px;
}
.checkout-container .payment-form .text-container {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
}

.checkout-container #payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.checkout-container #payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.checkout-container .checkout-btn {
  background: #22134b;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

.checkout-container .checkout-btn:hover {
  filter: contrast(115%);
}

.checkout-container .checkout-btn:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.checkout-container .spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.checkout-container .spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.checkout-container .spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.checkout-container .spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #22134b;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.checkout-container .spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #22134b;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/*-------------------------------------THANK YOU ---------------------------------------------------*/
.thank-you-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  /* width: 100vw; */
  /* margin: auto; */
  background-color: #fff;
}
.thank-you-container .thank-you-content {
  /* width: 600px;
  height: 600px; */
  width: 100%;
  height: 100vh;
  /* border-radius: 30px; */
  margin: auto;
  padding: 85px 40px;
  background-color: #ff8900;
  position: relative;
}
.thank-you-container .thank-you-content > h3 {
  color: #fff;
  font-size: 55px;
  text-align: center;
  font-weight: 600;
  margin-top: 24px;
}
.thank-you-container .thank-you-content .blue-part {
  position: absolute;
  width: 100%;
  height: 60%;
  left: 0;
  bottom: 0;
  background: rgb(125, 85, 226);
  /* border-radius: 80px 80px 30px 30px; */
  border-radius: 80px 80px 0px 0px;
}
.thank-you-container .thank-you-content .white-container {
  width: 800px;
  height: auto;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 30px 20px;
  text-align: center;
  border: 1px solid #bfc6da;
  background: #fff;
  border-radius: 50px;
  position: relative;
  z-index: 9;
}
.thank-you-container .thank-you-content .white-container > p {
  color: #ff8900;
  font-size: 42px;
  font-weight: 600;
  line-height: 49px;
}
.thank-you-container .thank-you-content .white-container > img {
  margin-top: 20px;
  margin-bottom: 40px;
}
.thank-you-container .thank-you-content .white-container .text-black {
  font-size: 15px;
  color: #22134b;
  font-weight: 600;
  line-height: 30px;
  padding: 0 70px;
  margin-bottom: 42px;
}
.thank-you-container .thank-you-content .white-container .text2-block {
  font-size: 15px;
  color: #22134b;
  font-weight: 500;
  line-height: 30px;
  padding: 0 80px;
}
.thank-you-container .thank-you-content .white-container .text-btn {
  background-color: #22134B;
  color: #fff;
  border-radius: 50px;
  padding: 8px 80px;
  font-size: 18px;
}
.thank-you-container .thank-you-content .logo-container {
  position: relative;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thank-you-container .thank-you-content .logo-container > img {
  width: 200px;
}
.thank-you-container .thank-you-content .logo-container > p {
  color: #fff;
  font-size: 14px;
  margin-top: 16px;
  margin-top: 16px;
}

@media only screen and (min-width: 993px) {
  .slider .item h6 span {
    top: 1px;
  }
}
@media only screen and (max-width: 992px) {
  .checkout-container .payment-form {
    width: 100%;
    min-width: initial;
  }
  .thank-you-container .thank-you-content {
    height: 100%;
    border-radius: 0px;
    padding: 17px 11px;
  }
  .thank-you-container .thank-you-content > h3 {
    margin-top: 0px;
    font-size: 46px;
  }
  .thank-you-container .thank-you-content .white-container > img {
    margin-top: 1px;
    margin-bottom: 22px;
  }
  .thank-you-container .thank-you-content .white-container {
    width: auto;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 30px 20px;
    text-align: center;
    border: 1px solid #bfc6da;
    background: #fff;
    border-radius: 50px;
    position: relative;
    z-index: 9;
  }
  .thank-you-container .thank-you-content .blue-part {
    border-radius: 80px 80px 0px 0px;
    height: 76%;
  }
  .thank-you-container .thank-you-content .white-container > p {
    font-size: 28px;
    line-height: 40px;
  }
  .thank-you-container .thank-you-content .white-container .text-black {
    padding: 0;
    margin-bottom: 28px;
  }
  .thank-you-container .thank-you-content .white-container .text2-block {
    padding: 0;
  }
  .mobile-wraper {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #7000ff;
    background: linear-gradient(15deg, #7000ff 0%, #bd00ff 100%);
  }
  .mobile-headerr {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 56px;
    margin-bottom: 15px;
  }
  .mobile-headerr .mobile-herder-text {
    margin-top: 20px;
    color: #000000;
    font-size: 15px;
  }
  .mobile-logo {
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    width: 100%;
    height: auto;
    position: relative;
  }
  .content h3 {
    color: #fff;
    font-size: 38px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 20px;
    white-space: nowrap;
  }
  .content h3 span {
    font-weight: 700;
    color: #fff;
  }
  .content .step-box {
    width: 100%;
    height: auto;
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 18px;
    margin-bottom: 30px;
  }
  .content .step-box h4 {
    text-align: center;
    color: #c09;
    font-size: 39px;
    font-weight: 400;
    margin-bottom: 0;
  }
  .content .step-box h4 span {
    font-weight: 700;
    color: #8509b1;
  }
  .form-group > label {
    font-size: 20px;
  }
  .form-group .error {
    color: #ec00b1;
    font-size: 13px;
  }
  .content .step-box p {
    color: #8509b1;
    font-size: 19px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
  }
  .content .step-box .list {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }
  .content .step-box .list .icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #a134f6;
    color: #fff;
    flex: 0 0 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    margin-right: 20px;
  }
  .content .step-box .list p {
    color: #22134b;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    text-align: left;
    margin: 0;
  }
  .content .ai-box {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 40px;
  }
  .content .ai-box p {
    color: #fff;
    white-space: nowrap;
    text-align: right;
    font-size: 28px;
    font-weight: 400;
    margin-bottom: 2px;
  }
  .content .ai-box p span {
    background: #fff;
    opacity: 0.9;
    color: #8d00ff;
    border-radius: 8px;
    padding: 0 10px;
    white-space: nowrap;
  }
  .content .start-btn {
    width: 100%;
    height: auto;
    border-radius: 20px;
    background: #ec00b1;
    border: none;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    padding: 10px 0;
    margin-bottom: 40px;
  }
  .mobile-top-container {
    min-height: 50%;
    display: block;
    padding-top: 0;
    background: linear-gradient(
      180deg,
      rgba(155, 106, 254, 1) 68%,
      rgba(125, 85, 226, 1) 100%
    );
    border-radius: 0 0 50px 50px;
  }
  .left-container .left-content .onbheader {
    font-size: 34px;
    font-weight: 400;
    text-align: center;
  }
  .slider {
    margin-bottom: 30px;
  }
  .slider .item {
    min-height: 260px;
    padding: 18px;
  }
  .slider .item p {
    font-size: 19px;
  }
  .slider .item h3 {
    font-size: 28px;
  }
  .slider .item h3 br {
    display: none;
  }
  .slider .item h4 {
    font-size: 24px;
  }
  .slider .item h4 br {
    display: none;
  }
  .slider .item h6 {
    font-size: 15px;
  }
  .slider .item h6 br {
    display: none;
  }
  .slider .item h6 span {
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
  }
  .slider .carousel-indicators {
    bottom: -35px !important;
  }
  .slider .carousel-control-prev {
    left: -30px !important;
    width: auto !important;
  }
  .slider .carousel-control-next {
    right: -30px !important;
    width: auto !important;
  }
  .wraper {
    width: 100%;
    min-height: 100vh;
  }
  .mobile-form-container {
    width: 100%;
    min-height: 50%;
    padding-top: 50px;
    display: block;
  }
  .head-title {
    margin-top: 0;
    text-align: center;
  }
  .head-title h3 {
    font-size: 30px;
    color: #7d55e2;
  }
  /* .form-group > label {
    font-size: 18px;
    margin-bottom: 30px;
  } */
  .form-group .form-label {
    font-size: 18px;
    margin: 0 !important;
  }
  .form-group .radio-btn-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .form-group .radio-btn-group .radio-text {
    font-size: 16px;
  }
  .mobile-form {
    margin-bottom: 16px !important;
  }
  .form-group .input-grouped {
    display: flex;
    flex-direction: column;
  }
  .form-group .form-company-group {
    display: flex;
    flex-direction: column;
  }
  .input-grouped .company-single {
    width: 100%;
  }
  .input-grouped .name-group {
    flex-direction: column;
  }
  .input-grouped .name-group .name-single {
    width: 100%;
    margin-bottom: 20px;
  }
  .input-grouped .name-group .bottom {
    margin: 0 !important;
  }
  .agreement-group .agreement p {
    font-size: 16px;
  }
  .input-form {
    font-size: 16px !important;
  }
  .styled-form {
    display: flex;
    flex-direction: column;
    align-content: space-between;
  }
  .btn-form-container {
    margin-bottom: 30px;
    margin-top: 10px;
    flex-direction: column;
  }
  .form-group .form-company-group {
    gap: 2px;
  }
  .form-btn {
    font-size: 24px;
  }
  .back-btn {
    font-size: 24px;
    margin-right: 0;
  }
  .styled-form .btn-box {
    width: 100%;
    height: auto;
    position: relative;
    text-align: center;
  }
  .styled-form .btn-box .back-btn {
    color: #520070;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    background: #fff;
    padding: 3px 20px;
    border: none;
    border-radius: 10px;
  }
  .styled-form .btn-box .next-btn {
    color: #fff;
    background: #7100ff;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    padding: 3px 20px;
    border-radius: 10px;
    border: none;
  }
  .form-group {
    margin-bottom: 50px;
  }
  .right-container {
    width: 100%;
    min-height: unset;
  }
  .disclosure-title {
    margin-top: 30px;
  }
  .thanks-container {
    margin-top: 30px;
    padding: 0 30px;
  }
  .disclosure-container {
    padding: 0 8px;
  }
  .disclosure-title h4 {
    font-size: 18px;
  }
  .right-disclosure-text {
    font-size: 14px;
  }
  .thanks-container > h3 {
    font-size: 26px;
    text-align: center;
    text-transform: capitalize;
    margin-bottom: 20px;
  }
  .thanks-container > p {
    font-size: 16px;
    text-align: center;
  }
  .thanks-container .thanks-p {
    font-size: 14px;
    margin-top: 10px;
  }
  .right-wrapper .logo img {
    width: 190px;
  }
  .agreement-group .error {
    color: #ec00b1;
    font-size: 13px;
  }
  .Launch-modal .modal-dialog {
    margin: 0;
  }
  .Launch-modal .modal-content .modal-body {
    padding: 50px 0px 0px 0px;
  }
  .Launch-modal .modal-content .modal-body .close-btn {
    background: #ff8900;
    position: absolute;
    right: 2px;
    top: 5px;
    border: none;
    border-radius: 50%;
  }
  .Launch-modal .modal-content .modal-head > h1 {
    font-size: 30px;
  }
  .Launch-modal .modal-content .modal-head > p {
    font-size: 24px;
  }
  .Launch-modal .modal-content .blue-part > h1 {
    font-size: 30px;
  }
  .Launch-modal .modal-content .blue-part .stepper-modal {
    padding-left: 32px;
    padding-right: 32px;
  }
  .Launch-modal .modal-content .blue-part .step-modal .step-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Launch-modal .modal-content .blue-part .step-number-modal {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
  }
  .Launch-modal .modal-content .blue-part .step-round-modal {
    width: 30px;
    height: 30px;
    margin-left: 0;
  }
  .Launch-modal .modal-content .blue-part .step-content-modal {
    font-size: 18px;
  }
  .Launch-modal .modal-content .blue-part .trail-section {
    margin-bottom: 40px;
    margin-top: 0;
    padding: 0;
  }
  .Launch-modal .modal-content .blue-part .trail-container {
    background-color: #ff8a00;
    border-radius: 30px;
    height: auto;
    padding: 15px 30px 3px 30px;
    /* width: 100%; */
    /* margin: auto; */
    margin-left: 30px;
    margin-right: 30px;
  }
  .Launch-modal .modal-content .blue-part .trail-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .Launch-modal .modal-content .blue-part .trail-price-modal {
    flex-direction: column;
    gap: 0px;
    justify-content: flex-start;
    /* padding: 0 30px; */
  }
  .Launch-modal
    .modal-content
    .blue-part
    .trail-price-modal
    .price-box-modal
    > img {
    width: 31px;
  }
  .Launch-modal
    .modal-content
    .blue-part
    .trail-price-modal
    .price-box-modal
    > p {
    font-size: 22px;
  }
  .Launch-modal .modal-content .blue-part .modal-btn-container .modal-btn {
    font-size: 22px;
    padding: 10px 14px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .form-group > label {
    font-size: 16px;
  }
  .input-for {
    font-size: 16px;
  }
}
@media screen and (min-width: 1922px) and (max-width: 2560px) {
  .right-content .btn {
    width: 400px;
    padding: 15px 0px;
  }
  .right-wrapper {
    align-items: flex-start;
    padding-top: 100px;
  }
  .form-group {
    margin-bottom: 35px;
  }
  .form-group > label {
    font-size: 20px;
  }
  .input-for {
    font-size: 20px;
  }
  .left-container {
    align-items: flex-start;
    padding-top: 200px;
  }
  .thanks-container {
    margin-top: 60px;
  }
  .slider {
    margin-top: 80px;
  }
  .slider .item p {
    margin-bottom: 20px;
  }
}
