.not-found-container {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 50px;
  }
  
  h2 {
    font-size: 3em;
    color: #d9534f; 
  }
  