body {
  margin: 0;
  padding: 0;
  font-family: "SF UI Display", sans-serif;
  scroll-behavior: smooth;
}
br {
  display: block !important;
}
/* .section-title{
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}
.section-title h3{
  font-size: 50px;
  color: #fff;
  font-weight: 700;
}
.section-title h3 .pink-b{
  color: #D0009C;
}
.section-title h3 .blue-b{
  color: #8509B1;
} */
.wrapper {
  margin: 0;
  padding: 0;
  /* background:#080808 url(./images/restoBackground.jpg) no-repeat; */
  background-size: 100% 100%;
  width: 100%;
  /* font-family: 'Roboto Mono', monospace; */
  font-family: "SF UI Display", sans-serif;
}
.wrapper .content {
  width: 100%;
  background: #080808 url(./images/restoBackground.jpg) no-repeat;
  min-height: 100vh;
  flex: 0 0 100%;
  background-size: 100% 100%;
}
.wrapper .content .header {
  width: 100%;
  height: auto;
  /* position: sticky; */
  top: 0;
  z-index: 999;
  padding-top: 15px;
  padding-bottom: 15px;
  position: fixed;
  /* background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(94,1,180,1) 0%); */
  /* background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(94,1,180,1) 47%); */
}
.headerSolid {
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(94, 1, 180, 1) 0%
  );
}
.logo img {
  margin-left: 30px;
  width: 265px;
}
.header .logo-mobile {
  display: none;
}
/* .header .container {
  max-width: 1250px;
} */
.header .menu {
  background: #44005c99;
  border-radius: 30px;
  justify-content: space-between;
  /* margin-right: 44px; */
}
.header .center {
  margin-right: 245px;
}
.header .menu .nav-link {
  color: #fff;
  font-size: 18px;
  padding: 15px 35px !important;
  transition: 0.3s;
}
.header .menu .nav-link.gen-btn {
  background: #8cbaff;
  color: #000;
  border-radius: 30px;
  text-decoration: none;
  display: table;
  font-weight: 600;
}
/* .header .menu .nav-link:hover{
  color: #C09;
} */
.wrapper .content .header-section {
  text-align: center;
  margin: 0px 50px 60px;
  padding-top: 118px;
}
.wrapper .content .header-section .header-section-text {
  font-size: 45px;
  font-weight: 600;
  color: #fff;
  line-height: 60px;
}
.wrapper .content .header-section .header-section-span {
  color: #ffb800;
}

.banner {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 30px;
  padding-bottom: 48px;
}

.banner .carousel-control-next,
.banner .carousel-control-prev {
  display: none;
}
.banner .carousel-indicators {
  margin-bottom: 26px;
}
.banner .carousel-indicators [data-bs-target] {
  width: 20px !important;
  height: 20px !important;
  border-radius: 50%;
}
.banner .image {
  width: 100%;
  height: auto;
  position: relative;
  min-height: 620px;
  padding: 0 10px 0 25px;
  /* padding-top: 50px; */
}
.banner .image img {
  width: 100%;
}
.banner .image2 {
  width: 100%;
  height: auto;
  position: relative;
  padding-top: 30px;
  min-height: 620px;
  padding-top: 30px;
  align-items: center;
  padding-bottom: 20px;
}
.banner .image2 img {
  width: 100%;
}
.banner .image2 p {
  font-size: 29px;
  line-height: 17px;
  color: #fff;
  text-align: center;
  margin-bottom: 30px;
  font-weight: 700;
  font-family: Gilroy;
}
.banner .image img.banner-mobile {
  display: none;
}
.banner .image .banner-icon {
  width: auto;
  width: 156px;
  transition: 0.3s;
  position: absolute;
}
.banner .image .banner-icon:hover {
  transform: scale(1.1, 1.1);
}
.banner .image .banner-icon.icon-1 {
  left: 35px;
  top: -15px;
}
.banner .image .banner-icon.icon-2 {
  right: 65px;
  top: 150px;
}
.banner .image .banner-icon.icon-3 {
  left: 65px;
  bottom: 120px;
}
.banner .image .banner-icon.icon-4 {
  right: 60px;
  bottom: 75px;
}
.banner .text {
  width: 100%;
  height: auto;
  position: relative;
  padding-right: 30px;
}
.banner .text.mobile {
  display: none;
}
.banner .text h1 {
  color: #fff;
  font-weight: 600;
  font-size: 73px;
  /* font-size: calc(100 * (41vw/1500)); */
  line-height: 118%;
  margin-bottom: 65px;
  letter-spacing: -0.03em;
  font-family: "Proxima Nova";
}
.banner .text h1 .input-text-head {
  color: #ffb800;
}
.banner .text .input-text-para {
  color: #fff;
  font-weight: 600;
  font-size: 59px;
  /* font-size: calc(100 * (41vw/1500)); */
  line-height: 121%;
  margin-bottom: 44px;
  letter-spacing: -0.03em;
  font-family: "Proxima Nova";
  letter-spacing: 0px;
}
.banner .text .input-text-para span {
  font-size: 29px;
  color: #fff;
  font-weight: 400;
}
.banner .text h3 {
  color: #ef0c0c;
  font-size: 22px;
}
.banner .text .form-box {
  width: 100%;
  height: auto;
  position: relative;
}
.banner .text .form-box p {
  font-weight: 700;
  font-size: calc(100 * (22vw / 1500));
  line-height: 145%;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.banner .text .form-box p span {
  font-size: calc(100 * (16vw / 1500));
  position: relative;
  top: -10px;
  color: red;
}
.banner .text .form-box .us-only {
  font-size: 12px;
  font-weight: 400;
  color: #fff;
  border: 1px solid #80ff00;
  border-radius: 5px;
  top: 0;
  padding: 2px;
  white-space: nowrap;
}
.banner .text .form-box .form-control {
  width: 100%;
  background: transparent;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #fff;
  font-style: normal;
  font-weight: 300;
  font-size: calc(100 * (20vw / 1500));
  padding: 11px 0;
  color: #fff;
}
.banner .text .form-box input::placeholder {
  color: #c0b8e8;
  font-size: calc(100 * (20vw / 1500));
}
.banner .text .form-box .input-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 15px solid #ffaa2a;
  /* background-color: #4cda21; */
  position: absolute;
  top: 123px;
  right: 21px; /* Adjust as needed */
  /* animation: blink 1s infinite; Blinking animation */
  animation: pulse 1s ease-out infinite;
  opacity: 0;
}
@keyframes pulse {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.1, 1.1);
    opacity: 0;
  }
}
.banner .text .form-box .form-control:focus {
  box-shadow: none;
  outline: none;
}
.banner .text .form-box .form-control::placeholder {
  color: #c0cff4;
  font-size: 26.729px;
  font-weight: 400;
  line-height: normal;
}
.type-text {
  color: #fff !important;
}
.banner .text .form-box .phoneLabel {
  position: absolute;
  font-size: calc(100 * (20vw / 1500));
  color: #fff;
  top: 12px;
}
.banner .text .form-box .color-box {
  width: 100%;
  height: auto;
  padding-top: 15px;
  padding-left: 55px;
}
.banner .text .form-box .custom-check {
  margin-right: 22px;
}
.banner .text .form-box .custom-check input {
  display: none;
}
.banner .text .form-box .custom-check span {
  display: table;
  width: 43px;
  height: 43px;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  border: 2px solid #fff;
}
.banner .text .form-box .custom-check input:checked ~ span {
  border: 1px solid #fff;
}
.banner .text .form-box .custom-check input:checked ~ span:before {
  content: "";
  position: absolute;
  top: 9px;
  left: 5px;
  width: 27px;
  height: 14px;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  transform: rotate(-45deg);
}
.banner .text .form-box .fileupload {
  margin-top: 15px;
}
.banner .text .form-box .fileupload input {
  display: none;
}
.banner .text .form-box .fileupload img {
  cursor: pointer;
}
.banner .text .form-box .fileupload span {
  font-weight: 300;
  font-size: 20px;
  line-height: 145%;
  letter-spacing: -0.02em;
  color: #a8a4b5;
  font-family: "Roboto Mono", monospace;
  margin-left: 15px;
  position: relative;
  top: 8px;
}
.banner .text .form-box .comment {
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: #a8a4b5;
  margin-top: 40px;
}
.banner .text .form-box .comment-l {
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 0px;
}
.banner .text .form-box .comment-le {
  font-weight: 400;
  font-size: 17px;
  line-height: 100%;
  color: #fff;
  margin-top: -12px;
  margin-bottom: 10px;
}
.banner .text .btn-box {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
}
.banner .text .btn-box .right {
  display: flex;
  align-items: center;
}
.banner .text .btn-box .back-btn {
  background: none;
  border: 0;
  font-weight: 400;
  font-size: calc(100 * (18vw / 1500));
  line-height: 100%;
  color: #ffffff;
  margin-right: 185px;
  white-space: nowrap;
  padding: 0 !important;
}
.banner .text .btn-box .ok-btn {
  padding: 10px 21px;
  border-radius: 31px;
  background: #ffaa2b;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  font-size: calc(100 * (20vw / 1500));
  line-height: 145%;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: 700;
  border: none;
  white-space: nowrap;
  margin-left: 10px;
}
.banner .text .btn-box .ok-btn .btn-tick {
  color: #fff;
}
.banner .text .btn-box p span {
  color: #fff;
}
.btn-loader {
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.banner .text .btn-box .disable-btn {
  padding: 10px 21px;
  border-radius: 10px;
  background: linear-gradient(90deg, #bc91cb 0%, #623a96 100%) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-size: calc(100 * (20vw / 1500));
  line-height: 145%;
  letter-spacing: -0.04em;
  color: #ffffff;
  font-weight: 700;
  border: none;
  white-space: nowrap;
}
.banner .text .btn-box .gen-btn {
  letter-spacing: 1.5px;
}

.banner .text .btn-box .ok-btn img {
  color: #fff !important;
  margin-left: 7px;
  margin-top: -8px;
  width: calc(100 * (16vw / 1500));
}
.banner .text .btn-box .gen-btn img {
  margin-left: 0;
}
.banner .text .btn-box p {
  font-size: calc(100 * (16vw / 1500));
  color: #fff;
  margin: 0;
  font-weight: 300;
  white-space: nowrap;
}
.banner .text .btn-box p span {
  font-weight: 600;
}

.banner-work {
  width: 100%;
  height: auto;
  background-color: #fff;
  padding-top: 80px;
  padding-bottom: 100px;
  scroll-margin-top: 50px;
}
.banner-work .text {
  width: 100%;
  height: auto;
  position: relative;
}
.banner-work .text .line {
  position: absolute;
  top: 150px;
  left: 25px;
}
.banner-work .text h3 {
  font-size: 45px;
  color: #4e008a;
  margin-bottom: 40px;
  text-align: center;
}
.banner-work .text .item {
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  margin-bottom: 60px;
  align-items: center;
}
.banner-work .text .item.two {
  padding-left: 15px;
}
.banner-work .text .item.three {
  padding-left: 95px;
}
.banner-work .text .item .icon {
  width: 60px;
  height: 60px;
  background: #242027;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  border-radius: 50%;
  margin-right: 35px;
  flex: 0 0 60px;
}
.banner-work .text .item .right h6 {
  color: #ff00bf;
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  font-family: "Proxima Nova";
}
.banner-work .text .item .right h6 span {
  color: #22134b;
  font-size: 24px;
  margin: 0;
  font-weight: 400;
}
.banner-work .text h3 span {
  font-weight: 700;
  color: #ff00bf;
}
.banner-work .about {
  width: 100%;
  height: 100%;
  position: relative;
  padding-left: 60px;
  display: flex;
  align-items: center;
}
.banner-work .image {
  width: 100%;
  height: auto;
  position: relative;
  display: none;
}
.banner-work .image img {
  width: 100%;
}
.banner-work .about h2 {
  font-size: 64px;
  font-weight: 600;
  color: #1a0c42;
  margin-bottom: 50px;
  font-family: "Proxima Nova";
  text-transform: uppercase;
}
.banner-work .about p {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  color: #4f457d;
  font-family: "Proxima Nova";
}
.banner-work .about p span {
  color: #7b00dc;
  font-weight: 600;
}
.banner-work .about h2 span {
  color: #7b00dc;
}
.banner-work .about h2 span .text-small {
  font-size: 40px;
}
.banner-stepper {
  width: 100%;
  /* height: 100%; */
  margin-top: 30px;
  /* margin-bottom: 100px; */
  padding-left: 22px;
  display: flex;
  flex-direction: column;
}
.banner-step {
  display: flex;
  align-items: center;
}
.banner-step-number {
  background-color: #242027;
  color: #fff;
  border-radius: 50%;
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  margin-right: 10px;
  font-weight: 500;
}
.banner-step-content {
  font-size: calc(100 * (24vw / 1920));
  font-weight: 400;
  line-height: 135%;
  color: #7b00dc;
  font-weight: 800;
  margin-left: 20px;
}
.stepper-span {
  color: #a194de;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}
.banner-divider {
  border-left: 3px solid #a7fcc4;
  height: 50px;
  margin: 0 10px 0 32px;
}
.banner-work .banner-work-header-right {
  color: #1a0c42;
  font-size: 80px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  padding-top: 50px;
}
.banner-work .banner-work-section-right {
  color: #2a2b2c;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px; /* 157.576% */
  letter-spacing: -0.33px;
  padding-top: 38px;
  padding-bottom: 154px;
}
.purple {
  color: #7b00dc;
}
.pink {
  color: #ff00bf;
  font-weight: 700;
}

.description-container {
  width: 100%;
  height: auto;
  position: relative;
  scroll-margin-top: 80px;
}
.description-container .features {
  width: 100%;
  height: auto;
  padding-top: 90px;
  padding-bottom: 140px;
  background: linear-gradient(
    180deg,
    rgba(97, 97, 97, 0.1) 2.48%,
    rgba(255, 255, 255, 0) 102.37%
  );
}
.description-container .features .text {
  width: 100%;
  height: auto;
  position: relative;
}
.description-container .features .text h3 {
  font-size: 75px;
  font-weight: 600;
  line-height: normal;
  color: #7b00dc;
  margin-bottom: 30px;
  line-height: 80px;
  font-family: "Proxima Nova";
  text-transform: uppercase;
}
.description-container .features .text h3 .text-small {
  font-size: 40px;
  color: #7b00dc;
}
.description-container .features .text p {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
  font-family: "Proxima Nova";
  color: #4f457d;
  text-wrap: wrap;
  padding-right: 21px;
}
.description-container .features .text .para2 {
  margin-top: 25px;
}
.description-container .features .text .para3 {
  margin-top: 25px;
}
.description-container .features .text p span {
  color: #7b00dc;
  font-weight: 600;
}
.description-container .features .text h3 span {
  color: #1a0c42;
}
.description-container .features .image {
  width: 100%;
  height: auto;
  margin-top: -114px;
}
.description-container .features .image img {
  width: 100%;
}
.description-container .sub-features {
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 100px;
}
.description-container .sub-features .item {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 78px;
}
.for-mobile {
  display: none;
}
.description-container .sub-features .item h3 {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  color: #7b00dc;
  text-align: left;
  margin-bottom: 80px;
  margin-left: 144px;
}
.description-container .sub-features .item h3 .text-small {
  font-size: 22px;
}
.description-container .sub-features .item h3 p {
  font-size: 15px;
  font-weight: 400;
  color: #4f457d;
  margin-top: 8px;
}
.description-container .sub-features .item h3 span {
  color: #1a0c42;
}
.description-container .sub-features .item .image {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.description-container .sub-features .item .image .back {
  /* width: 100%;
  height: auto; */
  width: 360px;
  height: 370px;
  position: relative;
}
.description-container .sub-features .item .image .phone {
  position: absolute;
  bottom: 35px;
  left: 0;
  right: -72px;
  margin: auto;
  width: 60%;
  transition: 0.3s;
}
.description-container .sub-features .item .image:hover .phone {
  bottom: 40px;
}
.description-container .sub-features .item .more-container {
  position: absolute;
  display: flex;
  left: 236px;
  bottom: -18px;
}
.description-container .sub-features .item .more-container .question-img {
  width: 22px;
}
.description-container .sub-features .item .btun {
  display: table;
  color: #160039;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  margin: auto;
  padding: 6px 27px;
  border-radius: 30px;
  border: 2.064px solid #5653e3;
  background-color: #fff;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  /* padding-left: 8px; */
}
.description-container .sub-features .item .btun:hover {
  background: #7b00dc;
  border-color: #7b00dc;
  color: #fff;
}
.description-container .beni-scroll {
  scroll-margin-top: 70px;
}
.description-container .choose-sec {
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 120px;
}
.description-container .choose-sec .heading {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.description-container .choose-sec .heading h2 {
  font-size: 75px;
  font-style: normal;
  font-weight: 600;
  font-family: "Proxima Nova";
  color: #7b00dc;
  text-transform: uppercase;
  margin-bottom: 18px;
}
.description-container .choose-sec .heading h2 span {
  color: #061737;
}
.description-container .choose-sec .heading p {
  font-size: 23px;
  font-weight: 500;
  /* color: #22134b; */
  color: #7b00dc;
  margin-bottom: 4rem;
  font-family: "Proxima Nova";
}
.description-container .choose-sec .heading p span {
  font-weight: 600;
  color: #22134b;
  font-family: "Proxima Nova";
}
.description-container .choose-sec .heading p .heading-para {
  font-weight: 600;
  color: #7b00dc;
  font-family: "Proxima Nova";
}
.description-container .choose-sec .choose-slider {
  overflow: hidden;
  padding: 50px 0;
}
.description-container .choose-sec .choose-slider .owl-stage-outer {
  border-radius: 50px;
  background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
  overflow: unset;
}
.description-container .choose-sec .owl-theme .item {
  height: 230px;
  transform: translate3d(0, 0, 0);
  transform: scale(0.9);
  transition: all 0.25s ease-in-out;
  margin: 0px 0;
}

.description-container .choose-sec .owl-theme .owl-item .item .text {
  width: 100%;
  height: 100%;
  background: #ccc;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  position: relative;
  padding: 25px;
  border: 8px solid rgba(86, 19, 138, 0.2);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.description-container .choose-sec .owl-theme .owl-item .item .text h4 {
  color: #9000ff;
  font-size: 32px;
  font-weight: 700;
}
.description-container .choose-sec .owl-theme .owl-item .item .text h4.pink {
  color: #c09;
}
.description-container .choose-sec .owl-theme .owl-item .item .text h5 {
  color: #22134b;
  font-size: 31px;
  font-weight: 400;
  line-height: 102%;
  margin-top: 16px;
}
.description-container .choose-sec .owl-theme .owl-item .item .text h5 span {
  color: #19bf88;
}
.description-container .choose-sec .owl-theme .owl-item .item .text h6 {
  color: #22134b;
  font-size: 19px;
  font-weight: 400;
  margin-bottom: 20px;
}
.description-container .choose-sec .owl-theme .owl-item .item .text p {
  color: #4f457d;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}
.description-container .choose-sec .owl-theme .owl-item .item .text .tag {
  display: table;
  position: absolute;
  right: -15px;
  top: 4px;
  background: #56138a;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0px 20px;
}
.description-container .choose-sec .owl-theme .owl-item.center {
  z-index: 99;
}
.description-container .choose-sec .owl-theme .owl-item.center .item .text {
  -webkit-transform: scale(1.55, 1.55);
  -ms-transform: scale(1.55, 1.55);
  transform: scale(1.55, 1.55);
  background: #fff;
  z-index: 99;
}
.description-container .choose-sec .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background: no-repeat;
  font-size: 50px;
  color: #fff;
  padding: 0;
}
.description-container .choose-sec .owl-carousel .owl-nav .owl-next:hover {
  background: none;
}
.description-container .choose-sec .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: no-repeat;
  font-size: 50px;
  color: #fff;
  padding: 0;
}
.description-container .choose-sec .owl-carousel .owl-nav .owl-prev:hover {
  background: none;
}
.description-container .choose-sec .owl-carousel .owl-dots {
  margin-top: 30px;
}
.description-container .choose-sec .owl-carousel .owl-dots span {
  width: 29px;
  height: 29px;
}
.description-container .choose-sec .owl-theme .owl-dots .owl-dot.active span,
.description-container .choose-sec .owl-theme .owl-dots .owl-dot:hover span {
  background: #56138a;
}
.description-container .case-scroll {
  scroll-margin-top: 60px;
}
.description-container .success-generated {
  width: 100%;
  height: auto;
  background: linear-gradient(0deg, #eddeff 0%, #fff 100%);
  padding-bottom: 80px;
}
.description-container .success-generated .image {
  width: 100%;
  height: auto;
  position: relative;
  margin-bottom: 60px;
}
.description-container .success-generated .image img {
  width: 100%;
}
.description-container .success-generated .text {
  color: #7b00dc;
  font-size: 58px;
  font-style: normal;
  font-weight: 600;
  font-family: "Proxima Nova";
  line-height: 65px;
  padding-left: 42px;
}
.description-container .success-generated .text span {
  color: #22134b;
  font-size: 75px;
}
.description-container .success-generated .success-slider .item {
  width: 100%;
  height: auto;
}
.description-container .success-generated .success-slider .item .image {
  width: 100%;
  height: auto;
  margin-bottom: 27px;
}
.description-container .success-generated .success-slider .item .image img {
  width: 100%;
}
.description-container .success-generated .success-slider .item .btun {
  display: table;
  color: #160039;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  margin: auto;
  padding: 10px 25px;
  border-radius: 30px;
  border: 2.064px solid #5653e3;
  text-decoration: none;
  transition: 0.3s;
}
.description-container .success-generated .success-slider .item .btun:hover {
  /* background: #7b00dc;
  border-color: #7b00dc; */
  color: #fff;
}
.description-container .success-generated .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: -3%;
  top: 33%;
  transform: translateY(-50%);
  background: no-repeat;
  font-size: 50px !important;
  color: #9c8eda;
  padding: 0;
}
.description-container
  .success-generated
  .owl-carousel
  .owl-nav
  .owl-next:hover {
  background: none;
}
.description-container .success-generated .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: -3%;
  top: 33%;
  transform: translateY(-50%);
  background: no-repeat;
  font-size: 50px !important;
  color: #9c8eda;
  padding: 0;
}
.description-container
  .success-generated
  .owl-carousel
  .owl-nav
  .owl-prev:hover {
  background: none;
}
.description-container .modal-scroll {
  scroll-margin-top: 50px;
}
.model-sec {
  width: 100%;
  height: auto;
  position: relative;
  padding: 80px 0;
  background: linear-gradient(236deg, #7c00a7 25.37%, #390081 80.15%);
}
.model-sec .heading {
  font-size: 73px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  font-family: "Proxima Nova";
  margin-bottom: 20px;
  text-align: center;
}
.model-sec .para {
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: #fff;
  font-family: "SF UI Display";
  margin-bottom: 10px;
  text-align: center;
}
.model-sec .container {
  width: 100%;
  overflow: hidden;
  height: 105%;
}

.model-sec .container .row-prize {
  /* max-width: 1200px; */
  margin: auto;
  display: flex;
  gap: 30px;
}
.model-sec .container .row-prize .item {
  width: 25%;
  height: 348px;
}
.model-sec .container .row-prize .item .card {
  background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
  border-radius: 40px;
  padding: 36px 20px 0 20px;
  position: relative;
  height: 100%;
}
.model-sec .container .row-prize .item .card > p {
  text-align: center;
  color: aliceblue;
  font-size: 35px;
  line-height: 0;
}
.model-sec .container .row-prize .item .card .inner-card {
  background: rgb(44, 7, 53);
  background: linear-gradient(
    210deg,
    rgba(44, 7, 53, 1) 0%,
    rgba(114, 0, 154, 1) 100%
  );
  color: #fff;
  border-radius: 40px;
  padding: 20px;
  position: absolute;
  left: -5px;
  bottom: -15px;
  top: 80px;
  right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.model-sec .container .row-prize .item .card .inner-card .include {
  text-align: center;
  font-size: 25px;
  text-transform: uppercase;
}
.model-sec .container .row-prize .item .card .inner-card h6 {
  text-align: center;
  font-size: 34px;
  color: #efc203;
}
.model-sec .container .row-prize .item .card .inner-card .more-order {
  text-align: center;
  font-size: 34px;
  color: #efc203;
  margin-bottom: 46px;
}
.model-sec .container .row-prize .item .card .inner-card .prize {
  text-align: center;
  font-size: 22px;
}
.model-sec .container .row-prize .item .card .inner-card .prize span {
  font-size: 62px;
  color: #fff;
}
.model-sec .container .row-prize .item .card .inner-card .prize .dollar {
  font-size: 36px;
  color: #fff;
}
.model-sec .container .row-prize .item .card .inner-card .per-prize {
  text-align: center;
  font-size: 23px;
}
.model-sec .container .row-prize .item .card .inner-card .speak {
  font-size: 30px;
  text-decoration: underline;
  cursor: pointer;
}
.model-sec .trail-section {
  width: 100%;
  padding: 0px 60px;
  margin-top: 52px;
}
.model-sec .trail-section .trail-container {
  width: 100%;
  height: auto;
  background-color: #ff00bf;
  border-radius: 50px;
  padding: 30px;
}
.model-sec .trail-section .trail-container .trail-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.model-sec .trail-section .trail-container .trail-content .trail-price {
  display: flex;
  flex-direction: row;
  gap: 46px;
  justify-content: center;
}
.model-sec
  .trail-section
  .trail-container
  .trail-content
  .trail-price
  .price-box {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}
.model-sec
  .trail-section
  .trail-container
  .trail-content
  .trail-price
  .price-box
  > img {
  width: 30px;
}
.model-sec
  .trail-section
  .trail-container
  .trail-content
  .trail-price
  .price-box
  > p {
  color: #fff;
  font-size: 26px;
  font-weight: 600;
}
.model-sec .trail-section .trail-container .trail-content .price-btn-container {
  margin: auto;
  margin-top: 35px;
  margin-bottom: 12px;
}
.model-sec
  .trail-section
  .trail-container
  .trail-content
  .price-btn-container
  .price-btn {
  background-color: #303030;
  color: #fff;
  outline: none;
  padding: 16px 181px;
  border: none;
  border-radius: 61px;
  font-size: 40px;
  font-weight: 600;
  cursor: pointer;
  line-height: 38px;
}
.model-sec
  .trail-section
  .trail-container
  .trail-content
  .price-btn-container
  .price-btn
  > span {
  font-size: 25px;
  font-weight: 500;
}
/* .model-sec .gen-btn-container {
  margin-top: 95px;
} */
.model-sec .heading-collaboration {
  font-size: 35px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  font-family: "SF UI Display";
  margin-bottom: 70px;
  margin-top: 70px;
  text-transform: uppercase;
}
.model-sec .collaboration-image {
  /* width: 200px !important; */
}
/* .model-sec .owl-carousel .owl-stage-outer .owl-stage .owl-item {
  width: 200px !important;
} */
/* .model-sec .owl-carousel .owl-stage-outer .owl-stage .owl-item {

} */
.model-sec .owl-carousel .owl-nav .owl-prev:hover {
  background: none;
}
.model-sec .owl-carousel .owl-nav .owl-prev {
  position: absolute;
  left: 0%;
  top: 33%;
  transform: translateY(-50%);
  background: no-repeat;
  font-size: 50px !important;
  color: #9c8eda;
  padding: 0;
}
.model-sec .owl-carousel .owl-nav .owl-next {
  position: absolute;
  right: -3%;
  top: 33%;
  transform: translateY(-50%);
  background: no-repeat;
  font-size: 50px !important;
  color: #9c8eda;
  padding: 0;
}
.model-sec .text {
  width: 100%;
  height: auto;
  position: relative;
}
.model-sec .text p {
  color: #fff;
  font-family: "Proxima Nova";
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 50px;
}
.model-sec .text .list-item {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 35px;
}
.model-sec .text .list-item img {
  width: 54px;
  flex: 0 0 54px;
  margin-right: 30px;
}
.model-sec .text .list-item p {
  font-size: 40px;
  font-weight: 500;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  margin: 0;
}
.model-sec .offer-pad {
  padding: 60px 60px 0 0;
  position: relative;
}
.model-sec .offer-box {
  width: 100%;
  height: 500px;
  border-radius: 70px;
  background: #2c0735;
  position: relative;
  z-index: 9;
  padding: 60px;
}
.model-sec .offer-box h2 {
  color: #fff;
  font-size: 105px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
}
.model-sec .offer-box p {
  color: #fff;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
}
.model-sec .offer-box h6 {
  color: #fff;
  font-size: 31px;
  font-style: normal;
  font-weight: 500;
  position: absolute;
  right: 60px;
  top: 60px;
}
.model-sec .back {
  content: "";
  width: calc(100% - 60px);
  height: 500px;
  border-radius: 70px;
  position: absolute;
  left: 60px;
  top: 0;
  background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
}
.model-sec .gen-btun {
  color: #fff;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  display: table;
  background: #ff00bf;
  text-decoration: none;
  padding: 20px 50px;
  border-radius: 40px;
  margin: auto;
}

.price-slider {
  margin-top: 115px;
  padding: 0px 93px;
}
.model-sec .price-slider .ant-slider-horizontal .ant-slider-rail {
  height: 8px;
  top: 10px;
  background-color: #d5b6fc;
}

.model-sec .price-slider .ant-slider-horizontal .ant-slider-track {
  height: 8px;
}
.model-sec .price-slider .ant-slider .ant-slider-track {
  background-color: #ff00bf;
  top: 10px;
}
.model-sec .price-slider .ant-slider .ant-slider-handle::after {
  content: "";
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 45px;
  height: 45px;
  border: 8px solid #000;
  background-color: #fff;
  box-shadow: 0 0 0 2px #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s,
    height 0.2s, box-shadow 0.2s;
}
.model-sec .price-slider .ant-slider-horizontal .ant-slider-handle {
  inset-block-start: -7px;
}
.ant-tooltip {
  font-size: 25px !important;
  z-index: 0;
}
.ant-tooltip .ant-tooltip-arrow {
  display: none;
}
.ant-tooltip .ant-tooltip-inner {
  color: #000;
  background-color: #ff00bf;
  width: auto;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
}
.ant-tooltip .ant-tooltip-content {
  left: 20px;
  width: auto;
  line-height: 30px;
}
.model-sec .cost-container {
  width: 100%;
  padding: 0px 93px;
  margin-top: 65px;
}
.model-sec .cost-container .cost-content {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(233px, 1fr)
  ); /* Adjust column width and gap */
  gap: 10px; /* Adjust gap between items */
  justify-content: center; /* Center items horizontally */
  margin-top: 7px;
}
.model-sec .cost-container::after {
  content: "";
  display: table;
  clear: both; /* Clear floats */
}
.model-sec .cost-container .cost-content .cost-item {
  margin: auto;
  background-color: #e5e6e9;
  border-radius: 15px;
  padding: 21px 30px 8px 30px;
  width: 240px;
}
.model-sec .cost-container .cost-content .cost-name {
  color: #000;
  margin-bottom: 0;
  font-size: 23px;
  font-weight: 600;
}
.model-sec .cost-container .cost-content .cost-number {
  color: #ff00bf;
  font-weight: 700;
  font-size: 38px;
}
.model-sec .cost-container .cost-sample-container {
  margin-top: 29px;
  margin-bottom: 14px;
  display: flex;
  justify-content: space-around;
}
.model-sec .cost-container .cost-sample-container .cost-sample {
  color: #fff;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  margin-bottom: 2px;
}
.model-sec .cost-container .cost-sample-container .cost-sample > span {
  color: #ff00bf;
}

.footer {
  /* height: 80vh; */
  background-size: 100% 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  background: linear-gradient(236deg, #1a003b 25.37%, #0b0019 80.15%);
}
.footer-box {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0;
}
.footer-box .footer-group {
  display: flex;
  flex-direction: column;
}
.footer-group .bottom-footer-text {
  margin-top: 12px;
}
.bottom-footer-box {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 50px 0px;
}
.footer-box p {
  color: #dccfff;
  margin: 0;
}
.footer-box p a {
  color: #dccfff;
  margin: 0;
}
.bottom-footer-box .bottom-footer-text {
  color: #dccfff;
  margin: 0;
  font-size: 15px;
}
.footer-box img {
  width: 204px;
}
.footer .footer-container {
  width: 100%;
  background: #080808 url(./images/restoFooter.png) no-repeat;
  /* min-height: 80vh; */
  flex: 0 0 100%;
}
.footer .footer-container .footer-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 58px;
}
.footer-container .footer-header .footer-text {
  color: #fff;
  font-size: 96.82px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.footer .footer-container .footer-sub-part {
  margin: 0px 150px;
  display: flex;
  padding-top: 45px;
  padding-bottom: 75px;
}
.footer-container .footer-sub-part .footer-sub-text-part {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer-sub-part .footer-sub-text-part .footer-sub-text {
  color: #fff;
  font-size: 45px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 172px;
  padding-bottom: 24px;
}
.footer-stepper {
  /* width: 100%; */
  /* height: 100%; */
  margin-top: 30px;
  /* margin-bottom: 100px; */
  padding-left: 160px;
  display: flex;
  flex-direction: column;
}
.footer-step {
  display: flex;
  align-items: center;
}
.footer-step-number {
  color: #fff;
  border-radius: 50%;
  width: 63px;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  margin-right: 10px;
  font-weight: 500;
}
.footer-step-content {
  font-size: calc(100 * (24vw / 1920));
  font-weight: 400;
  line-height: 135%;
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  margin-left: 20px;
  letter-spacing: 1px;
}
.footer-divider {
  height: 30px;
  margin: 0 10px 0 32px;
}
.footer-sub-part .footer-sub-text-part .footer-btn {
  margin-left: 171px;
  margin-top: 43px;
  padding: 18px 45px;
  border-radius: 50px;
  border: none;
  background-color: #ff00bf;
  color: #fff;
  font-size: 25.753px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.footer-container .footer-sub-part .footer-price {
  width: 50%;
  display: flex;
  justify-content: center;
}
.footer-sub-part .footer-price .footer-price-light {
  width: 627px;
  height: 520px;
  position: relative;
  background: rgb(112, 0, 255);
  background: linear-gradient(
    77deg,
    rgba(112, 0, 255, 1) 2%,
    rgba(189, 0, 255, 0.8155637254901961) 39%
  );
  border-radius: 50px;
}
.footer-sub-part .footer-price .footer-price-light .footer-price-dark {
  position: absolute;
  width: 627px;
  height: 520px;
  position: relative;
  background: #2c0735;
  border-radius: 50px;
  top: 48px;
  right: 48px;
}
.footer-price-dark .price-container {
  padding: 45px;
  display: flex;
  flex-direction: column;
}
.footer-price-dark .price-container .price-top {
  display: flex;
}
.footer-price-dark .price-container .price-top .top-left {
  padding-top: 35px;
  padding-bottom: 35px;
}
.footer-price-dark .price-container .price-top .top-right {
  padding-left: 70px;
}
.footer-price-dark .price-container .price-top .price {
  color: #fff;
  font-size: 104.13px;
  font-style: normal;
  font-weight: 700;
  line-height: 111px;
}
.footer-price-dark .price-container .price-top .order {
  color: #fff;
  font-size: 31.79px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 138.408% */
}
.footer-price-dark .price-container .price-bottom .option {
  color: #fff;
  font-size: 26.234px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footer .footer-container .footer-main {
  width: 100%;
  height: 150px;
  background-color: #080808;
  display: flex;
  justify-content: center;
}
.footer .footer-container .footer-main .footer-main-container {
  width: 100%;
  margin: 0px 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-container .footer-main .footer-main-container .footer-part {
  padding: 50px;
}
.footer-main .footer-main-container .footer-part .footer-text {
  color: #dccfff;
  font-size: 19.092px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 26px;
}
.AI-modal .modal-dialog {
  max-width: 600px;
}
.AI-modal.odrdash .modal-dialog {
  max-width: 1200px;
}
.AI-modal.lastmile .modal-dialog {
  max-width: 900px;
}
.AI-modal.formPopup .modal-dialog {
  max-width: 560px;
}
.AI-modal .modal-content {
  border-radius: 30px;
  border: 5px solid #5a00ca;
}
.AI-modal .modal-content .modal-body {
  padding: 60px 30px 20px 30px;
}
.AI-modal .modal-content .modal-body .tick-icon {
  width: 34px;
  height: auto;
  margin: 0;
  position: relative;
  top: -13px;
}
.AI-modal .modal-content .modal-body .close-btn {
  background: #fff;
  position: absolute;
  right: 10px;
  top: 12px;
  border: none;
}
.AI-modal .modal-content .modal-body .item img {
  margin-bottom: 9px;
}
.AI-modal .modal-content .modal-body h3 {
  color: #7b00dc;
  text-align: center;
  font-family: "SF UI Display";
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: 170%;
  letter-spacing: -0.84px;
}
.AI-modal .modal-content .modal-body h4 {
  color: #7b00dc;
  font-family: "SF UI Display";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 170%;
  letter-spacing: -0.84px;
}
/* .AI-modal .modal-content .modal-body .item .right-disclosure-text {
  color: #22134b;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
} */
/* .AI-modal .modal-content .modal-body .disclosure-title h4 {
  color: #22134b;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
} */
.AI-modal .modal-content .modal-body ul li {
  font-size: 18px;
  font-family: "SF UI Display";
}
.AI-modal .modal-content .modal-body .custom-list {
  list-style: none;
}
.AI-modal .modal-content .modal-body .custom-list ul {
  padding-left: 0px;
}
.AI-modal .modal-content .modal-body span {
  color: #ff00bf;
}
.AI-modal .modal-content .modal-body .item {
  width: 100%;
  height: auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
.AI-modal .modal-content .modal-body .item p {
  margin-top: 7px;
  color: #061737;
  padding-left: 32px;
  font-size: 21px;
  font-style: normal;
  font-family: "SF UI Display";
  font-weight: 500;
}
.AI-modal .modal-content .modal-body .item p .try-here a {
  color: #5a00ca;
  font-weight: 600;
  text-decoration: none;
}
.AI-modal .modal-content .modal-body .item h6 {
  color: #5a00ca;
  font-size: 20px;
  font-family: "SF UI Display";
  font-weight: 700;
  line-height: 26px;
  margin-top: 10px;
}
.AI-modal .modal-content .modal-body .item h6 a {
  color: #5a00ca;
  font-size: 22px;
  font-family: "SF UI Display";
  font-weight: 700;
  line-height: 26px;
  margin-top: 10px;
  text-decoration: none;
}
.css-typing li {
  border-right: 0.15em solid #fff;
  font-family: "SF UI Display";
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 14px;
}
.css-typing li:nth-child(1) {
  width: 18.3em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing li:nth-child(2) {
  width: 21.5em;
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing li:nth-child(3) {
  width: 22.3em;
  opacity: 0;
  -webkit-animation: type3 3s steps(40, end);
  animation: type3 2s steps(40, end);
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@keyframes type {
  0% {
    width: 0;
  }
  60% {
    border-right: 0.12em solid #000;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  60% {
    border-right: 0.12em solid #000;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.12em solid #000;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.12em solid #000;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.12em solid #000;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
  }
}
.css-typing-bottom {
  margin-bottom: 20px; /* Adjust margin as needed */
}

.custom-list {
  list-style-type: none;
  padding: 0;
}
.custom-list li {
  font-size: 18px; /* Adjust font-size as needed */ /* Adjust margin as needed */
}

/* 
.css-typing-bottom li {
  border-right: 0.15em solid #fff;
  font-family: "Proxima Nova";
  font-size: 18px;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 14px;
}
.css-typing-bottom li:nth-child(1) {
  width: 35.9em;
  -webkit-animation: type 2s steps(40, end);
  animation: type 2s steps(40, end);
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing-bottom li:nth-child(2) {
  margin-bottom: 20px;
  width: 21.5em;
  opacity: 0;
  -webkit-animation: type2 2s steps(40, end);
  animation: type2 2s steps(40, end);
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.css-typing-bottom li:nth-child(3) {
  width: 15.3em;
  opacity: 0;
  -webkit-animation: type3 3s steps(40, end);
  animation: type3 2s steps(40, end);
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.css-typing-bottom li:nth-child(4) {
  width: 8.3em;
  opacity: 0;
  -webkit-animation: type4 3s steps(40, end);
  animation: type4 2s steps(40, end);
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
} */
/* @keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid #000;
  }
  100% {
    border: none;
  }
}

@-webkit-keyframes type {
  0% {
    width: 0;
  }
  99.9% {
    border-right: 0.15em solid #000;
  }
  100% {
    border: none;
  }
}

@keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@-webkit-keyframes type2 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
    border: none;
  }
}

@keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type3 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
  }
}
@keyframes type4 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes type4 {
  0% {
    width: 0;
  }
  1% {
    opacity: 1;
  }
  65% {
    border-right: 0.15em solid #000;
  }
  100% {
    opacity: 1;
  }
} */
.timerProgress {
  width: 100%;
  height: auto;
  position: relative;
  background: rgba(224, 212, 230, 0.83);
  border-radius: 30px;
  overflow: hidden;
  margin-top: 33px;
  margin-bottom: 20px;
}
.timerProgress .bar {
  height: 10px;
  background: #5a00ca;
}
.grecaptcha-badge {
  visibility: hidden;
}
.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: #080808 url(./images/restoBackground.jpg) no-repeat;
  background-size: 100% 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-loader .loader {
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 5px solid #fff;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
@media screen and (max-width: 991px) {
  .wrapper .content .header-section {
    text-align: center;
    margin: 0px 15px 0px;
  }
  .header .center {
    margin-right: 0;
  }
  .wrapper .content .header-section .header-section-text {
    font-size: 21px;
    line-height: 30px;
  }
  .header .menu {
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(94, 1, 180, 1) 0%
    );
  }
  .wrapper .content .header {
    /* background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(94, 1, 180, 1) 47%
    ); */
  }
  .banner {
    padding-top: 6px;
  }
  .banner .text .input-text-para {
    font-size: 22px;
  }
  .header .menu .nav-link.gen-btn {
    width: 100%;
  }
  .banner .text .input-text-para span {
    font-size: 15px;
  }
  .banner .image .banner-icon {
    width: 95px;
  }
  .banner .image {
    width: 320px;
    min-height: 360px;
    margin: auto;
  }
  .banner .image2 {
    width: 320px;
    min-height: 360px;
    margin: auto;
  }
  .banner .text h1 {
    font-size: 34px;
    text-transform: uppercase;
    margin-bottom: 30px;
  }
  .banner .text h3 {
    text-wrap: wrap;
    font-size: 18px;
  }
  .banner .text {
    margin-top: 30px;
    padding: 0 10px;
  }
  .banner .text .form-box .color-box {
    width: 100%;
    height: auto;
    padding-top: 15px;
    padding-left: 20px;
  }
  .banner .text .form-box .custom-check {
    margin-right: 9px;
  }
  .banner .text .form-box p {
    font-size: 19px;
    margin-bottom: 9px;
  }
  .banner .text .form-box p br {
    display: none;
  }
  .banner .text .form-box .form-control {
    font-size: 14px;
  }
  .banner .text .form-box .form-control::placeholder {
    font-size: 16px;
  }
  .banner .text .form-box .phoneLabel {
    font-size: 14px;
  }
  .banner .text .form-box .comment-le {
    font-size: 12px;
    line-height: 16px;
  }
  .banner .text .btn-box .back-btn {
    font-size: 14px;
    margin-right: -20px;
  }
  .banner .text .btn-box .right {
    margin-left: -62px;
  }
  .banner .text .btn-box p {
    font-size: 12px;
  }
  .banner .text .btn-box .ok-btn {
    font-size: 16px;
    border-radius: 30px;
  }
  .banner .carousel-indicators {
    margin-bottom: 3px;
  }
  .banner .text .btn-box .ok-btn img {
    margin-left: 7px;
    margin-top: -5px;
    width: 16px;
  }
  .banner .text .form-box .input-circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 15px solid #ffaa2a;
    /* background-color: #4cda21; */
    position: absolute;
    top: 129px;
    right: 5px; /* Adjust as needed */
    /* animation: blink 1s infinite; Blinking animation */
    animation: pulse 1s ease-out infinite;
    opacity: 0;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      transform: scale(1.1, 1.1);
      opacity: 0;
    }
  }
  .banner-work .text h3 {
    font-size: 24px;
    text-align: center;
    font-weight: 800;
    margin-bottom: 25px;
  }
  .banner-work .text .item .icon {
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    font-size: 25px;
    margin-right: 15px;
    background: #ec8e00;
  }
  .banner-work .text .item .right h6 {
    font-size: 22px;
  }
  .description-container .sub-features .item .image .phone {
    top: -38px;
    width: 296px;
    right: -11px;
  }
  .banner-work .text .item .right h6 span {
    font-size: 20px;
  }
  .banner-work .text .item {
    margin-bottom: 23px;
    min-height: 76px;
  }
  .banner-work .text .line {
    top: 100px;
    left: 15px;
    height: 225px;
  }
  .banner-work .text .item.three {
    padding-left: 65px;
  }
  .banner-work .about {
    padding-left: 10px;
    padding-right: 10px;
    height: auto;
  }
  .banner-work .about h2 span .text-small {
    font-size: 21px;
  }
  .banner-work .about h2 {
    font-size: 30px;
    text-align: center;
    margin-bottom: 30px;
  }
  .banner-work {
    padding-bottom: 50px;
  }
  .banner-work .about p {
    font-size: 18px;
    line-height: 33px;
    font-weight: 500;
  }
  .banner-work .about p span {
    color: #5a00ca;
    font-weight: 700;
  }
  .banner-work .image img {
    border-radius: 35px;
  }
  .description-container .features {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .description-container .features .image {
    display: none;
  }
  .description-container .features .text h3 {
    font-size: 34px;
    line-height: 45px;
    text-align: center;
  }
  .description-container .features .text h3 .text-small {
    font-size: 21px;
  }
  .description-container .sub-features .item h3 {
    font-size: 27px;
    margin-bottom: 66px;
    margin-left: 0;
    text-align: center;
  }
  .description-container .sub-features .item .image .back {
    width: 96%;
    height: auto;
    position: relative;
  }
  .description-container .features .text p {
    font-size: 19px;
    line-height: 34px;
    text-align: left;
    padding: 0 15px;
  }
  .description-container .success-generated .text span {
    font-size: 34px;
  }
  .for-desktop {
    display: none;
  }
  .for-mobile {
    display: block;
  }
  .description-container .sub-features .item h3 p {
    font-size: 15px;
    margin-top: 15px;
  }
  .description-container .sub-features .item .image {
    margin-bottom: 0;
  }
  .description-container .sub-features .item {
    margin-bottom: 55px;
  }
  .description-container .sub-features {
    padding-bottom: 30px;
  }
  .description-container .choose-sec .heading h2 {
    font-size: 34px;
    margin-bottom: 20px;
  }
  .description-container .choose-sec .heading p {
    font-size: 21px;
    margin-bottom: 2rem;
    padding: 0 16px;
  }
  .description-container .choose-sec .heading p span {
    color: rgba(123, 0, 220, 0.6);
  }
  .owl-carousel .owl-item img {
    width: 70%;
  }
  .model-sec .owl-carousel .owl-nav .owl-prev {
    top: 28%;
  }
  .model-sec .owl-carousel .owl-nav .owl-next {
    top: 28%;
  }
  .model-sec .colla {
    margin: auto;
  }
  .description-container .sub-features .item .more-container {
    left: 89px;
    bottom: -19px;
  }
  .description-container .choose-sec .owl-theme .item {
    height: 267px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h5 {
    font-size: 28px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h6 {
    font-size: 19px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text p {
    font-size: 16px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text {
    transform: scale(1);
    padding: 30px 15px;
  }
  .description-container .choose-sec .owl-theme .owl-item.center .item .text {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    background: #fff;
    z-index: 99;
  }
  .description-container .choose-sec .choose-slider {
    padding: 0;
  }
  .description-container .success-generated .image {
    display: none;
  }

  .description-container .success-generated .success-slider .item .image {
    display: block;
  }
  .description-container .success-generated .owl-carousel .owl-nav .owl-prev {
    color: #fff;
  }
  .description-container .success-generated .owl-carousel .owl-nav .owl-next {
    color: #fff;
  }
  .description-container .success-generated .text {
    color: #7b00dc;
    font-size: 21px;
    width: 100%;
    text-align: center;
    line-height: 29px;
    padding-left: 0;
    margin-bottom: 35px;
  }
  .description-container .choose-sec {
    padding-bottom: 45px;
  }
  .model-sec {
    padding-top: 80px;
    padding-bottom: 63px;
  }
  .model-sec .heading {
    font-size: 34px;
    text-align: center;
  }
  .model-sec .para {
    font-size: 21px;
  }
  .model-sec .text p {
    font-size: 27px;
    text-align: center;
    line-height: 30px;
    font-weight: 600;
  }
  .model-sec .text {
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 35px;
  }
  .model-sec .container {
    padding: 0 15px;
  }
  .model-sec .text .list-item p {
    font-size: 22px;
    text-align: left;
  }
  .model-sec .text .list-item img {
    width: 33px;
    flex: 0 0 33px;
  }
  .model-sec .text .list-item {
    margin-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .model-sec .slider-container-mobile {
    width: 100%;
    height: auto;
    border: 2px solid #fff;
    border-radius: 30px;
    padding: 0 25px 36px 25px;
    margin-top: 26px;
  }
  .model-sec .slider-container-mobile .qty-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
  }
  .ant-tooltip .ant-tooltip-inner {
    display: none;
  }
  .model-sec .slider-container-mobile .qty-container .qty {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
  }
  .model-sec .slider-container-mobile .qty-container .qty-val {
    font-size: 28px;
    font-weight: 600;
    color: #ff00bf;
  }
  .model-sec .price-slider {
    margin-top: 11px;
    padding: 0 20px 0 10px;
  }
  .model-sec .cost-container {
    padding: 0;
    margin-top: 38px;
  }
  .model-sec .cost-container .cost-content {
    padding: 0;
    gap: 20px;
    justify-content: space-between;
  }
  .model-sec .cost-container .cost-content .cost-item {
    margin: auto;
    background-color: #e5e6e9;
    border-radius: 15px;
    padding: 4px 13px;
    width: 100%;
  }
  .model-sec .cost-container .cost-content .cost-item .cost-item-group {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .model-sec .cost-container .cost-content .cost-item .cost-sample {
    color: #5a00ca;
    font-size: 19px;
    line-height: 21px;
    text-align: center;
  }
  .model-sec .cost-container .cost-content .cost-item .cost-sample > span {
    color: #ff00bf;
  }
  .model-sec .cost-container .cost-content .cost-number {
    color: #ff00bf;
    font-size: 28px;
    font-weight: 600;
  }
  .model-sec .cost-container {
    padding: 0;
    margin-top: 38px;
  }
  .model-sec .cost-container .cost-content .cost-name {
    font-size: 20px;
  }
  .model-sec .trail-section {
    padding: 10px;
    margin-top: 0;
    margin-bottom: 40px;
    margin-top: 30px;
  }
  .model-sec .trail-section .trail-container .trail-content .trail-price {
    flex-wrap: wrap;
    gap: 0;
    justify-content: flex-start;
    padding: 0 30px;
  }
  .model-sec
    .trail-section
    .trail-container
    .trail-content
    .trail-price
    .price-box
    > p {
    font-size: 22px;
  }
  .model-sec
    .trail-section
    .trail-container
    .trail-content
    .price-btn-container {
    margin-top: 35px;
    margin-bottom: 35px;
  }
  .model-sec .trail-section .trail-container .trail-content .price-btn-container .price-btn {
    padding: 10px 42px;
    font-size: 23px;
    line-height: 35px;
    font-weight: 600;
}
.model-sec .trail-section .trail-container .trail-content .price-btn-container .price-btn > span {
  font-size: 20px;
}
  /* .model-sec .container .row-prize {
    flex-direction: column;
  }
  .model-sec .container .row-prize .item {
    width: 100%;
    margin-bottom: 54px;
  } */
  .model-sec .owl-themeOrder .owl-item .item {
    width: 100%;
    height: 316px;
    padding: 0 30px;
  }
  .model-sec .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: visible !important;
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  .model-sec .owl-themeOrder .owl-nav .owl-prev {
    position: absolute;
    left: -3%;
    top: 53%;
    transform: translateY(-50%);
    background: no-repeat;
    font-size: 50px !important;
    color: #9c8eda;
    padding: 0;
  }
  .model-sec .owl-themeOrder .owl-nav .owl-next {
    position: absolute;
    right: -3%;
    top: 53%;
    transform: translateY(-50%);
    background: no-repeat;
    font-size: 50px !important;
    color: #9c8eda;
    padding: 0;
  }
  .model-sec .owl-themeOrder .owl-item .item .card {
    background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
    border-radius: 40px;
    padding: 36px 20px 0 20px;
    position: relative;
    height: 100%;
  }
  .model-sec .owl-themeOrder .owl-item .item .card > p {
    text-align: center;
    color: aliceblue;
    font-size: 35px;
    line-height: 0;
    margin-top: 4px;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card {
    background: rgb(44, 7, 53);
    background: linear-gradient(
      210deg,
      rgba(44, 7, 53, 1) 0%,
      rgba(114, 0, 154, 1) 100%
    );
    color: #fff;
    border-radius: 40px;
    padding: 20px;
    position: absolute;
    left: -5px;
    bottom: -15px;
    top: 80px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .include {
    text-align: center;
    font-size: 23px;
    margin-bottom: 7px;
    text-transform: uppercase;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card h6 {
    text-align: center;
    font-size: 30px;
    color: #efc203;
    /* margin-top: 31px; */
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .more-order {
    text-align: center;
    font-size: 37px;
    color: #efc203;
    margin-bottom: 31px;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .prize {
    text-align: center;
    font-size: 22px;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .prize span {
    font-size: 62px;
    color: #fff;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .prize .dollar {
    font-size: 36px;
    color: #fff;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .per-prize {
    text-align: center;
    font-size: 21px;
  }
  .model-sec .owl-themeOrder .owl-item .item .card .inner-card .speak {
    font-size: 30px;
    text-decoration: underline;
    cursor: pointer;
  }
  .model-sec .gen-btn-container {
    margin-top: 64px;
  }
  .model-sec .offer-pad {
    padding: 20px 20px 0 0;
    margin-bottom: 35px;
    padding: 45px 65px 20px 30px;
  }
  .model-sec .offer-box {
    padding: 30px;
    height: 245px;
    border-radius: 40px;
  }
  .model-sec .back {
    height: 245px;
    border-radius: 40px;
    left: 46px;
    top: 17px;
    width: calc(100% - 80px);
  }
  .model-sec .offer-box h2 {
    font-size: 37px;
    margin-bottom: 35px;
  }
  .model-sec .offer-box p {
    font-size: 15px;
  }
  .model-sec .offer-box h6 {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .model-sec .gen-btun {
    color: #fff;
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    display: table;
    background: #ff00bf;
    text-decoration: none;
    padding: 20px 32px;
    border-radius: 40px;
    margin: auto;
  }
  .model-sec .heading-collaboration {
    font-size: 22px;
    margin-bottom: 40px;
    margin-top: 50px;
  }
  .model-sec .collaboration-image {
    width: 100% !important;
    margin-bottom: 35px;
    display: flex;
    justify-content: center;
  }
  .footer-box {
    display: block;
    text-align: center;
    padding: 20px 0;
    order: 2;
  }
  .bottom-footer-box {
    display: block;
    text-align: center;
    order: 1;
  }
  .bottom-footer-box .bottom-footer-text {
    padding: 8px 0;
    font-size: 14px;
  }
  .footer-box img {
    /* margin-bottom: 40px; */
    /* margin-right: 23px; */
    margin: auto;
    margin-bottom: 20px;
    width: 225px;
  }
  .footer-group .bottom-footer-text {
    margin-top: 0;
  }
  .footer-box p {
    font-size: 12px;
    margin-bottom: 14px;
  }

  .navbar-toggler {
    background: #8cbaff;
    border: none;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 5px;
  }
  .navbar-toggler:focus {
    border: none;
    box-shadow: none;
  }
  .header .menu .nav-link {
    text-align: center;
  }
  .banner .image .banner-icon.icon-1 {
    left: 40px;
    top: 0px;
  }
  .banner .image .banner-icon.icon-2 {
    right: 50px;
    top: 120px;
  }
  .banner .image .banner-icon.icon-1 {
    left: 40px;
    top: 0px;
  }
  .banner .image .banner-icon.icon-3 {
    left: 45px;
    bottom: 100px;
  }
  .banner .image .banner-icon.icon-4 {
    right: 46px;
    bottom: 65px;
  }
  .banner-work .text .item.two .icon {
    background: #aa00ad;
  }
  .banner-work .text .item.three .icon {
    background: #7b00dc;
  }
  .logo img {
    width: 220px;
    /* margin-left: 60px; */
  }
  .banner-work .image {
    display: block;
    margin: 50px 0;
  }
  .AI-modal .modal-content .modal-body {
    padding: 50px 15px;
  }
  .AI-modal .modal-content .modal-body .item p {
    font-size: 17px;
    padding-left: 18px;
  }
  .AI-modal .modal-content .modal-body .item p .try-here {
    text-decoration: underline;
  }
  .AI-modal .modal-content .modal-body .item h6 {
    font-size: 17px;
  }
  .AI-modal .modal-content .modal-body .item h6 a {
    font-size: 17px;
  }
  .AI-modal .modal-content .modal-body h3 {
    font-size: 30px;
  }
  .AI-modal .modal-content .modal-body .item p br {
    display: none;
  }
  .AI-modal .modal-content .modal-body h4 {
    line-height: 135%;
  }
  .css-typing li {
    font-size: 16px;
  }
  .AI-modal .modal-content .modal-body ul li {
    font-size: 16px;
  }
  .AI-modal .modal-content .modal-body .custom-list {
    padding-left: 0;
  }
  .page-loader {
    background: #080808 url(./images/restoBackground.jpg) no-repeat;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .header .menu .nav-link {
    font-size: 14px;
    padding: 15px 20px !important;
  }

  .wrapper .content .header-section .header-section-text {
    font-size: 32px;
    font-weight: 600;
    color: #fff;
    line-height: 45px;
  }
  .banner .text h1 {
    font-size: 60px;
  }
  .banner .text h3 {
    font-size: 17px;
  }
  .banner .image {
    min-height: 455px;
  }
  .banner .image2 {
    min-height: 455px;
  }
  .banner .image .banner-icon {
    width: 115px;
  }
  .banner .image .banner-icon.icon-1 {
    left: 30px;
    top: -5px;
  }
  .banner .image .banner-icon.icon-2 {
    right: 40px;
    top: 120px;
  }
  .banner .text .form-box input::placeholder {
    font-size: 18px;
  }
  .banner .image .banner-icon.icon-3 {
    left: 45px;
    bottom: 90px;
  }
  .banner .image .banner-icon.icon-4 {
    right: 35px;
    bottom: 60px;
  }
  .banner-work .text h3 {
    font-size: 35px;
  }
  .banner-work .text .item .right h6 {
    font-size: 22px;
  }
  .banner-work .text .item .right h6 span {
    font-size: 18px;
  }
  .banner-work .text .item .icon {
    width: 45px;
    height: 45px;
    flex: 0 0 45px;
    font-size: 24px;
  }
  .banner-work .text .item {
    margin-bottom: 65px;
  }
  .banner-work .text .line {
    position: absolute;
    top: 128px;
    left: 20px;
  }
  .banner-work .about h2 {
    font-size: 48px;
  }
  .banner-work .about p {
    font-size: 20px;
    line-height: 40px;
  }
  .description-container .features .text h3 {
    font-size: 60px;
    line-height: 65px;
  }
  .description-container .features .text p {
    font-size: 24px;
    line-height: 33px;
  }
  .description-container .features {
    padding-top: 50px;
    padding-bottom: 70px;
  }
  .description-container .features .image {
    margin-top: -100px;
  }
  .description-container .sub-features .item h3 {
    font-size: 28px;
  }
  .description-container .sub-features .item .btun {
    font-size: 13px;
  }
  .description-container .sub-features .item .image .phone {
    bottom: 52px;
  }
  .description-container .sub-features .item .more-container {
    left: 77px;
    bottom: -16px;
  }
  .description-container .choose-sec .heading h2 {
    font-size: 55px;
  }
  .description-container .success-generated .text span {
    font-size: 55px;
  }
  .description-container .choose-sec .heading p {
    font-size: 24px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h5 {
    font-size: 24px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h4.pink {
    font-size: 26px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text p {
    font-size: 16px;
    line-height: 18px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text .tag {
    font-size: 16px;
  }
  .description-container .success-generated .text {
    font-size: 45px;
    line-height: 46px;
  }
  .description-container .success-generated .success-slider .item .btun {
    font-size: 16px;
  }
  .model-sec .heading {
    font-size: 55px;
  }
  .model-sec .text p {
    font-size: 30px;
  }
  .model-sec .text .list-item img {
    width: 40px;
    flex: 0 0 40px;
  }
  .model-sec .text .list-item p {
    font-size: 28px;
  }
  .model-sec .offer-box {
    padding: 35px;
  }
  .model-sec .back {
    left: 60px;
    border-radius: 40px;
    height: 365px;
  }
  .model-sec .para {
    font-size: 29px;
  }
  .model-sec .container .row-prize .item {
    height: 299px;
  }
  .model-sec .container .row-prize .item .card .inner-card .include {
    font-size: 21px;
  }
  .model-sec .container .row-prize .item .card .inner-card h6 {
    font-size: 24px;
  }
  .model-sec .container .row-prize .item .card .inner-card .prize span {
    font-size: 46px;
  }
  .model-sec .container .row-prize .item .card .inner-card .prize .dollar {
    font-size: 33px;
  }
  .model-sec .container .row-prize .item .card .inner-card .per-prize {
    font-size: 20px;
  }
  .model-sec .container .row-prize .item .card .inner-card .more-order {
    font-size: 26px;
    margin-bottom: 37px;
  }
  .model-sec .container .row-prize .item .card .inner-card .speak {
    font-size: 21px;
  }
  .model-sec .heading-collaboration {
    font-size: 22px;
  }
  .model-sec .offer-box {
    border-radius: 40px;
    height: 365px;
  }
  .model-sec .offer-pad {
    padding: 30px 30px 0 0;
  }
  .model-sec .offer-box h2 {
    font-size: 60px;
  }
  .model-sec .offer-box p {
    font-size: 18px;
  }
  .model-sec .offer-box h6 {
    font-size: 22px;
    right: 40px;
    top: 40px;
  }
  .model-sec .gen-btun {
    font-size: 18px;
    padding: 10px 40px;
  }
  .model-sec .text .list-item {
    margin-bottom: 20px;
  }
  .footer-box p {
    font-size: 13px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .container {
    max-width: 1150px;
  }
  .wrapper .content .header {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .wrapper .content .header-section {
    margin: 0px 71px 25px;
    padding-top: 130px;
  }
  .header .menu .nav-link {
    font-size: 16px;
    padding: 15px 25px !important;
  }
  .wrapper .content .header-section .header-section-text {
    font-size: 20px;
  }
  .banner {
    padding-top: 0;
  }
  .banner .text h1 {
    font-size: 60px;
  }
  .banner .text .input-text-para {
    font-size: 40px;
  }
  .banner .text h3 {
    font-size: 20px;
  }
  .banner .image {
    min-height: 535px;
  }
  .banner .image2 {
    min-height: 535px;
  }
  .banner .carousel-indicators {
    margin-bottom: 34px;
  }
  .banner .image .banner-icon.icon-3 {
    left: 35px;
    bottom: 90px;
  }
  .banner .image .banner-icon.icon-4 {
    right: 30px;
    bottom: 50px;
  }
  .banner .text .form-box .left {
    padding-left: 44px !important;
  }
  .banner .image .banner-icon.icon-1 {
    left: 10px;
    top: -15px;
  }
  .description-container .sub-features .item .image .back {
    width: 350px;
    height: 332px;
}
  .description-container .sub-features .item .more-container {
    left: 200px;
    bottom: -18px;
  }
  .banner .image .banner-icon.icon-2 {
    right: 40px;
    top: 120px;
  }
  .banner-work .text .item .icon {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    font-size: 30px;
  }
  .banner-work .about h2 {
    font-size: 62px;
  }
  .banner-work .about p {
    font-size: 26px;
  }
  .banner .text .form-box .comment-le {
    line-height: 113%;
    font-size: 15px;
  }
  .banner .text .form-box p {
    margin-bottom: 12px;
  }
  .banner .text .btn-box {
  }
  .description-container .features .text h3 {
    font-size: 62px;
  }
  .description-container .features .text p {
    font-size: 24px;
    line-height: 35px;
  }
  .description-container .sub-features .item h3 p {
    font-size: 17px;
  }
  .description-container .features .image {
    margin-bottom: -145px;
  }
  .description-container .sub-features .item h3 {
    font-size: 33px;
  }
  .description-container .success-generated .text {
    font-size: 42px;
    line-height: 50px;
  }
  .description-container .success-generated .text span {
    font-size: 62px;
  }
  .description-container .choose-sec .heading h2 {
    font-size: 62px;
  }
  .description-container .choose-sec .heading p {
    font-size: 20px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h5 {
    font-size: 27px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text .tag {
    font-size: 17px;
  }
  .model-sec .heading {
    font-size: 70px;
  }
  .model-sec .text p {
    font-size: 34px;
  }
  .model-sec .text .list-item p {
    font-size: 30px;
  }
  .model-sec .offer-box {
    height: 441px;
  }
  .model-sec .back {
    height: 441px;
  }
  .model-sec .offer-box h2 {
    font-size: 75px;
    margin-bottom: 36px;
  }
  .model-sec .offer-box h6 {
    font-size: 24px;
  }
  .model-sec .container .row-prize .item .card .inner-card .speak {
    font-size: 28px;
  }
  .model-sec .offer-box p {
    font-size: 24px;
  }
  .model-sec .container .row-prize .item {
    height: 306px;
  }
  .model-sec .container .row-prize .item .card .inner-card .include {
    font-size: 20px;
    margin-bottom: 4px;
  }
  .model-sec .container .row-prize .item .card .inner-card h6 {
    font-size: 30px;
  }
  .model-sec .container .row-prize .item .card .inner-card .per-prize {
    font-size: 20px;
  }
  .model-sec .container .row-prize .item .card .inner-card .prize span {
    font-size: 60px;
  }
  .model-sec .container .row-prize .item .card .inner-card .more-order {
    margin-bottom: 32px;
  }
  .AI-modal.formPopup .modal-dialog {
    max-width: 600px;
  }
  .AI-modal .modal-content .modal-body .close-btn {
    right: 12px;
    top: 15px;
  }
}
@media screen and (min-width: 1501px) and (min-width: 1921px) {
  .container {
    max-width: 1900px;
  }
  .container-xl {
    max-width: 1900px;
  }
  .wrapper .content .header-section .header-section-text {
    font-size: 60px;
  }
  .wrapper .content .header {
    padding-top: 15px;
  }
  .wrapper .content .header-section {
    margin: 0px 50px 25px;
    padding-top: 147px;
  }
  .banner {
    padding-top: 30px;
  }
  .banner .text h1 {
    font-size: 116px;
  }
  .banner .text .form-box p img {
    height: 35px;
  }
  .banner .image {
    min-height: 895px;
  }
  .banner .image2 {
    min-height: 895px;
  }
  .banner .image .banner-icon {
    width: 225px;
  }
  .banner .image .banner-icon.icon-1 {
    left: 45px;
    top: -15px;
  }
  .banner .image .banner-icon.icon-2 {
    right: 105px;
    top: 210px;
  }
  .banner .image .banner-icon.icon-3 {
    left: 70px;
    bottom: 185px;
  }
  .banner .image .banner-icon.icon-4 {
    right: 90px;
    bottom: 120px;
  }
  .banner-work .text h3 {
    font-size: 65px;
  }
  .banner-work .text .item .right h6 {
    font-size: 46px;
  }
  .banner-work .text .item .right h6 span {
    font-size: 42px;
  }
  .banner-work .text .line {
    position: absolute;
    top: 150px;
    left: 22px;
    height: 465px;
  }
  .banner-work .text .item.three {
    padding-left: 120px;
  }
  .banner-work .about h2 {
    font-size: 115px;
  }
  .banner .carousel-indicators {
    margin-bottom: 26px !important;
  }
  .banner-work .about p {
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: 35px;
    color: #2a2b2c;
    font-family: "Proxima Nova";
  }
  .description-container .features .text h3 {
    font-size: 115px;
    font-weight: 800;
    line-height: normal;
    color: #7b00dc;
    margin-bottom: 30px;
    line-height: 120px;
    font-family: "Proxima Nova";
  }
  .description-container .features .text p {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 72px;
    font-family: "Proxima Nova";
  }
  .description-container .features .image {
    width: 100%;
    height: auto;
    margin-top: -210px;
  }
  .description-container .sub-features .item h3 {
    font-size: 60px;
  }
  .description-container .sub-features .item .btun {
    font-size: 30px;
  }
  .description-container .sub-features .item .image {
    min-height: 565px;
  }
  .description-container .choose-sec .heading h2 {
    font-size: 115px;
  }
  .description-container .choose-sec .heading p {
    font-size: 50px;
    font-weight: 500;
    color: #22134b;
    margin-bottom: 4rem;
  }
  .description-container .choose-sec .owl-theme .item {
    height: 315px;
  }
  .description-container .choose-sec .choose-slider {
    overflow: hidden;
    padding: 75px 0;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h5 {
    color: #22134b;
    font-size: 45px;
    font-weight: 400;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text h6 {
    color: #22134b;
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 20px;
  }
  .description-container .choose-sec .owl-theme .owl-item .item .text p {
    color: #22134b;
    font-size: 26px;
    font-weight: 400;
    line-height: 40px;
  }
  .description-container .success-generated .text {
    font-size: 115px;
  }
  .description-container .success-generated .success-slider .item .btun {
    font-size: 26px;
  }
  .model-sec .heading {
    font-size: 115px;
  }
  .model-sec .text p {
    color: #fff;
    font-family: "Proxima Nova";
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 50px;
  }
  .model-sec .text .list-item p {
    font-size: 45px;
    font-weight: 500;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
    margin: 0;
  }
  .model-sec .container .row-prize {
    display: flex;
    gap: 20px;
  }
  .model-sec .gen-btun {
    color: #fff;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    display: table;
    background: #ff00bf;
    text-decoration: none;
    padding: 20px 50px;
    border-radius: 50px;
  }
  .model-sec .offer-box {
    height: 600px;
  }
  .model-sec .offer-box h2 {
    font-size: 120px;
  }
  .model-sec .offer-box p {
    font-size: 40px;
  }
  .model-sec .offer-box h6 {
    font-size: 46px;
  }
  .footer-box img {
    width: 300px;
  }
  .footer-box p {
    font-size: 24px;
  }
  .header .menu .nav-link {
    font-size: 26px;
  }
}
@media screen and (min-width: 1922px) and (max-width: 2560px) {
  .wrapper .content .header {
    padding-top: 44px;
    padding-bottom: 44px;
  }
  .wrapper .content .header-section .header-section-text {
    font-size: 54px;
}
  .banner .text h1 {
    font-size: 100px;
    line-height: 110%;
    margin-bottom: 58px;
  }
  .banner .text .input-text-para {
    font-size: 80px;
}
  .banner-work .about h2 {
    font-size: 98px;
  }
  .banner-work .text h3 {
    font-size: 55px;
  }
  .banner-work .text .line {
    top: 195px;
    height: 451px;
  }
  .description-container .features .image {
    margin-top: -116px;
  }
  .banner-work .about p {
    font-size: 40px;
    line-height: 63px;
    color: #4f457d;
  }
  .description-container .features .text h3 {
    font-size: 111px;
  }
  .description-container .features .text p {
    font-size: 38px;
    line-height: 66px;
  }
  .description-container .sub-features .item h3 p {
    font-size: 26px;
  }
  .description-container .choose-sec .heading h2 {
    font-size: 111px;
  }
  .description-container .choose-sec .heading p {
    font-size: 35px;
  }
  .description-container .success-generated .text span {
    font-size: 111px;
  }
  .description-container .success-generated .text {
    font-size: 82px;
    line-height: 89px;
  }
  .description-container .sub-features .item .more-container {
    left: 370px;
    bottom: -17px;
}
  .description-container .sub-features .item .btun {
    font-size: 24px;
  }
  .description-container .sub-features .item .image .phone {
    top: -65px;
  }
  .description-container .sub-features .item .image .back {
    width: 69%;
    height: auto;
}
  .wrapper .content .header-section {
    margin: 0px 50px 25px;
    padding-top: 194px;
  }
  .banner .text .form-box .comment-le {
    font-size: 22px;
  }
  .banner {
    padding-top: 95px;
}
  .model-sec .container .row-prize {
    gap: 57px;
  }
  .model-sec .heading {
    font-size: 111px;
    margin-bottom: 10px;
  }
  .model-sec .para {
    font-size: 38px;
    margin-bottom: 73px;
  }
  .model-sec .container .row-prize .item .card > p {
    margin-top: 8px;
  }
  .model-sec .container .row-prize .item {
    height: 390px;
  }
  .model-sec .container .row-prize .item .card .inner-card h6 {
    font-size: 42px;
  }
  .model-sec .container .row-prize .item .card .inner-card .per-prize {
    font-size: 25px;
  }
  .model-sec .container .row-prize .item .card .inner-card .prize span {
    font-size: 75px;
  }
  .model-sec .container .row-prize .item .card .inner-card .more-order {
    font-size: 50px;
  }
  .model-sec .trail-section .trail-container .trail-content .trail-price .price-box > img {
    width: 47px;
}
.model-sec .trail-section .trail-container .trail-content .trail-price .price-box > p {
  font-size: 40px;
}
.model-sec .trail-section .trail-container .trail-content .price-btn-container .price-btn {
  font-size: 60px;
}
.model-sec .trail-section .trail-container .trail-content .price-btn-container .price-btn > span {
  font-size: 30px;
}
.ant-tooltip .ant-tooltip-inner {
  font-size: 30px;
}
.model-sec .cost-container .cost-content .cost-item {
  width: 270px;
}
.model-sec .cost-container .cost-content .cost-name {
  font-size: 28px;
}
.model-sec .cost-container .cost-content .cost-number {
  font-size: 48px;
}
.model-sec .cost-container .cost-sample-container .cost-sample {
  font-size: 34px;
}
  .AI-modal.formPopup .modal-dialog {
    max-width: 620px;
  }
  .banner .text .form-box .left {
    padding-left: 44px !important;
  }
}
