.news-wrapper {
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
}
.news-wrapper .header {
  width: 100%;
  height: auto;
  position: relative;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 999;
  background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
}
.news-wrapper .main-header {
  background: #fff;
}
.news-wrapper .main-header .logo {
  width: 200px;
  height: auto;
  position: relative;
}
.small-banner {
  background-color: #dae4f0;
  padding-top: 10rem;
  padding-bottom: 5rem;
  text-align: center;
}
.header .header-text {
  margin-top: 50px;
  width: 70%;
}
.header .header-text h3 {
  color: #fff;
  font-size: 70px;
  font-style: normal;
  font-weight: 600;
  line-height: 78.1px; /* 105.541% */
  text-transform: uppercase;
}
.header .header-text p {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 12px;
}
.news-wrapper-content {
  background: linear-gradient(
    3deg,
    rgba(97, 97, 97, 0.1) 2.48%,
    rgba(255, 255, 255, 0) 102.37%
  );
  height: auto;
}
.news-container {
  padding-top: 72px;
  padding-bottom: 50px;
}
.news-container .card-name {
  width: 280px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px; /* 293.336% */
  text-transform: uppercase;
  background: #7b00dc;
  margin-bottom: 25px;
}
.news-container .card-title {
  color: #061737;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 124.793% */
  text-transform: uppercase;
  cursor: pointer;
}
.news-container .card-desc {
  color: #4f457d;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px; /* 157.123% */
  margin-bottom: 20px;
  margin-top: 35px;
}
.news-container .card-body {
  padding: 36px;
}
.news-container .news-detail-header {
  display: flex;
  justify-content: space-between;
}
.news-container .news-detail-header .social-icons {
    display: flex;
    column-gap: 12px;
}
.news-container .news-detail-header .publisher-name {
  color: #5a00ca;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 46px; /* 238.849% */
  text-transform: uppercase;
  margin-bottom: 20px;
}
.news-container .card-title-detail {
  color: #061737;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 46px; /* 125% */
}
.news-container .time {
  color: #4f457d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 15px;
}
.news-container .list-head {
  color: #5a00ca;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.news-container .lists {
  margin-top: 35px;
}
.news-container .lists p {
  color: #22134b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
  cursor: pointer;
}
.news-container .lists p span {
  color: #a134f6;
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
}
@media screen and (max-width: 991px) {
  .news-container {
    padding-top: 48px;
    padding-bottom: 30px;
  }
  .header .header-text {
    margin-top: 8px;
    width: 100%;
  }
  .header .header-text h3 {
    font-size: 29px;
    font-weight: 600;
    line-height: 35px;
  }
  .header .header-text p {
    /* font-size: 20px;
    margin-top: 0; */
    display: none;
  }
  .news-container .news-detail-header {
    flex-direction: column;
    margin-bottom: 20px;
  }
  .news-container .news-detail-header .publisher-name {
    font-size: 17px;
    margin-bottom: 12px;
  }
  .news-container .card-title-detail {
    font-size: 26px;
    line-height: 36px;
  }
  .news-container .card-desc {
    font-size: 14px;
    margin-top: 20px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .header .header-text h3 {
    font-size: 60px;
    font-weight: 600;
    line-height: 67.1px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .header .header-text h3 {
    font-size: 69px;
    font-weight: 600;
  }
  .header .header-text p {
    font-size: 30px;
  }
}
@media screen and (min-width: 1501px) and (min-width: 1921px) {
  .header .header-text h3 {
    font-size: 70px;
    font-weight: 600;
  }
  .header .header-text p {
    font-size: 31px;
  }
}
@media screen and (min-width: 1922px) and (max-width: 2560px) {
  .header .header-text h3 {
    color: #fff;
    font-size: 74px;
    font-style: normal;
    font-weight: 600;
    line-height: 78.1px; /* 105.541% */
    text-transform: uppercase;
  }
  .header .header-text p {
    font-size: 36px;
  }
}
