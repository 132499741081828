.policy-wrapper {
    margin: 0;
    padding: 0;
    font-family: Proxima Nova;
  }
  .policy-wrapper .header {
    width: 100%;
    height: auto;
    position: relative;
    padding: 20px 0;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  .policy-wrapper .main-header {
    background: #fff;
  }
  .policy-wrapper .main-header .logo {
    width: 200px;
    height: auto;
    position: relative;
  }
  .small-banner {
    background-color: #dae4f0;
    padding-top: 10rem;
    padding-bottom: 5rem;
    text-align: center;
  }
  .main-content {
    margin-top: 100px;
  }
  .main-content h4 {
    font-size: 22px;
    font-weight: 700;
  }
  .main-content .social-icon {
    width: 100%;
    height: auto;
  }
  .main-content .social-icon ul {
    margin: 0;
    padding: 0;
  }
  .main-content .social-icon ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 7px;
    font-family: "Roboto", sans-serif;
  }
  .main-content .social-icon ul li a {
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgb(128, 128, 128);
    color: #fff;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
  }
  .main-footer {
    width: 100%;
    height: auto;
    position: relative;
    background: #111111;
    padding: 60px 0 75px;
  }
  .main-footer .logo-text {
    width: 100%;
    height: auto;
    position: relative;
  }
  .main-footer .logo-text h4 {
    color: #7d7d7d;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
  }
  .main-footer .list-box {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
  }
  .main-footer .list-box > ul > li {
    color: #fff;
    list-style: none;
    padding: 8px;
  }
  .main-footer .list-box > ul > li > a {
    color: #fff;
    text-decoration: none;
  }
  .main-footer .social-icon {
    width: 100%;
    height: auto;
  }
  .main-footer .social-icon ul {
    margin: 0;
    padding: 0;
    text-align: right;
  }
  .main-footer .social-icon ul li {
    display: inline-block;
    margin: 0;
    padding: 0;
    margin-left: 7px;
    font-family: "Roboto", sans-serif;
  }
  .main-footer .social-icon ul li a {
    display: flex;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: rgb(128, 128, 128);
    color: #111;
    font-size: 20px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-family: "Roboto", sans-serif;
  }
  .footer-bottom {
    background-color: #000;
    padding: 20px 0;
    text-align: center;
  }
  .footer-bottom p {
    color: #7d7d7d;
    font-family: "Roboto", sans-serif;
  }
  