.blog-wrapper {
  margin: 0;
  padding: 0;
  font-family: Proxima Nova;
}
.blog-wrapper .header {
  width: 100%;
  height: auto;
  position: relative;
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 999;
  background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
}
.blog-wrapper .main-header {
  background: #fff;
}
.blog-wrapper .main-header .logo {
  width: 200px;
  height: auto;
  position: relative;
}
.small-banner {
  background-color: #dae4f0;
  padding-top: 10rem;
  padding-bottom: 5rem;
  text-align: center;
}
.blog-banner {
  background-repeat: no-repeat;
}
.blog-banner .blog-banner-img {
  width: 100%;
  height: auto;
  display: block; /* This ensures that the image respects its container's width */
  margin: 0 auto; /* Center the image horizontally within its container */
}
.banner-footer {
  width: 100%;
  height: auto;
  background: linear-gradient(236deg, #bd00ff 25.37%, #7000ff 80.15%);
}
.banner-footer h3 {
  padding: 20px 0;
  text-align: center;
  color: #fff;
  font-family: Proxima Nova;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
  line-height: 78.1px; /* 122.031% */
  text-transform: uppercase;
}
.blog-wrapper-content {
  background: linear-gradient(
    3deg,
    rgba(97, 97, 97, 0.1) 2.48%,
    rgba(255, 255, 255, 0) 102.37%
  );
  height: auto;
  position: relative;
}
.blog-container {
  padding-top: 72px;
  padding-bottom: 50px;
}
.blog-container .card-image {
  height: 272px;
  overflow: hidden;
  object-fit: cover;
}
.blog-container .card-image-head {
  height: 205px;
  overflow: hidden;
  object-fit: contain;
}
.blog-container .card-title h1{
  color: #061737;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29.95px; /* 124.793% */
  text-transform: uppercase;
}
.blog-container .card-sub-title h2{
  color: #061737;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 29.95px; /* 124.793% */
  text-transform: uppercase;
}
.blog-container .card-desc {
  color: #4f457d;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 27.324px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.blog-container .detail-text {
  padding: 0 18px;
}
.blog-container .detail-text-blog {
  padding: 0 8px;
}
.blog-container .card-time {
  display: flex;
  align-items: center;
}
.blog-container .card-time img {
  padding-right: 7px;
}
.blog-container .footer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
}
.blog-container .footer-container .footer-text {
  color: #2a2b2c;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.blog-container .footer-container .footer-btn {
  outline: none;
  border: none;
  width: 160px;
  height: 37px;
  border-radius: 18px;
  background: #7b00dc;
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 5px 13px;
}
.blog-container .card-container {
  border: none;
  border-radius: 10px;
  background: rgba(220, 207, 255, 0.4);
}
.fixed-table-content {
  position: sticky;
  top: 120px;
}
.blog-container .list-head {
  color: #5a00ca;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-align: justify;
}
.blog-container .border {
  border-bottom: 1px solid #979797 !important;
}
.blog-container .lists-left {
  margin-top: 12px;
}
.blog-container .lists-left ul {
  list-style-type: none;
  padding: 0;
}
.blog-container .lists-left ul li {
  margin-left: 20px;
  line-height: 1;
}
.blog-container .lists-left ul li::before {
  content: "\2022"; /* Unicode character for a bullet point */
  color: #5a00ca;
  font-size: 34px;
  margin-right: 4px;
  margin-left: -16px;
}
.blog-container .lists-left ul li a {
  color: #22134b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  line-height: 1.4;
}
.blog-container .MsoNormal span{
  color: #4F457D;
}
.blog-container h3 span strong{
  color: #4F457D !important;
}
.blog-container ul li span{
  color: #4F457D;
}
.blog-container ol li span{
  color: #4F457D;
}
.blog-container .social-icons {
  display: flex;
  column-gap: 12px;
  margin-top: 20px;
}
.blog-container .lists {
  margin-top: 35px;
}
.blog-container .lists p {
  color: #22134b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 25px;
  cursor: pointer;
}
.blog-container .lists p span {
  color: #a134f6;
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
}
.blog-container .card-author-container {
  display: flex;
}
.blog-container .card-author-container p {
  color: #4f457d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  padding-right: 20px;
}
.blog-container .card-footer-container {
  display: flex;
  align-items: baseline;
  justify-content: end;
}
.blog-container .card-footer-container p {
  padding-right: 16px;
}
@media screen and (max-width: 991px) {
  .blog-container {
    padding-top: 48px;
    padding-bottom: 30px;
  }
  .blog-container .MsoNormal span iframe{
    height: 195px !important;
    object-fit: contain !important;
    overflow: hidden !important;
    width: 314px !important;
  }
  .blog-container .MsoNormal img{
    height: 195px;
    object-fit: contain;
    overflow: hidden;
    width: 314px;
  }
  .blog-container .card-image {
    height: 173px;
    overflow: hidden;
    object-fit: contain;
  }
  .blog-container .card-image-head {
    height: 173px;
  }
  .blog-container .social-icons {
    margin-bottom: 20px;
  }
  .banner-footer h3 {
    font-size: 17px;
    font-weight: 500;
    padding: 0;
  }
  .blog-container .footer-container .footer-text {
    font-size: 13px;
  }
  .blog-container .footer-container .footer-btn {
    width: 150px;
  }
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-footer h3 {
    font-size: 45px;
    font-weight: 500;
  }
  .blog-container .MsoNormal span iframe{
    height: 100% !important;
    object-fit: contain !important;
    overflow: hidden !important;
    width: 380px !important;
  }
  .blog-container .MsoNormal img{
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    width: 380px;
  }
  .blog-container .card-title h1{
    font-size: 22px;
  }
  .blog-container .card-image-head {
    height: 145px;
  }
  .blog-container .list-head {
    font-size: 23px;
  }
  .blog-container .footer-container .footer-text {
    font-size: 12px;
    line-height: 14px;
  }
  .blog-container .footer-container .footer-btn {
    width: 150px;
    font-size: 10px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .banner-footer h3 {
    font-size: 51px;
    font-weight: 500;
  }
  .blog-container .MsoNormal span iframe{
    height: 100% !important;
    object-fit: contain !important;
    overflow: hidden !important;
    width: 473px !important;
  }
  .blog-container .MsoNormal img{
    height: 100%;
    object-fit: contain;
    overflow: hidden;
    width: 473px;
  }
  .blog-container .card-image-head {
    height: 177px;
  }
  .blog-container .footer-container .footer-text {
    line-height: 15px;
  }
  .blog-container .footer-container .footer-btn {
    font-size: 12px;
    padding: 5px 18px;
    width: 137px;
    height: 32px;
  }
  .blog-container .list-head {
    font-size: 22px;
  }
}
@media screen and (min-width: 1501px) and (min-width: 1921px) {
}
@media screen and (min-width: 1922px) and (max-width: 2560px) {
  .blog-banner .blog-banner-img {
    /* Add specific styles for larger resolutions if needed */
    width: 100%;
    height: auto;
  }
  .blog-container .card-image{
    height: 407px;
  }
  .blog-container .card-image-head {
    height: 305px;
    object-fit: cover;
  }
}
